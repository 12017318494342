import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

const Membership = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box backgroundColor='black' sx={{ padding: isMobile ? '4rem' : '8rem' }}>
      <Container maxWidth='xl' disableGutters>
        <Stack direction='column' alignItems='center' spacing={4}>
          <Typography variant='h4' sx={{ fontWeight: 500, color: 'white' }}>
            BE PART OF IT
          </Typography>
          <Link to='/apply' style={{ textDecoration: 'none' }}>
            <Button
              variant='outlined'
              size='large'
              sx={{
                height: '2.5rem',
                color: 'white',
                borderColor: 'white',
                borderRadius: 0,
                width: '14rem',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderColor: 'white',
                  color: 'black'
                }
              }}
            >
              Apply for Membership
            </Button>
          </Link>
        </Stack>
      </Container>
    </Box>
  )
}

export default Membership
