import Stack from '@mui/material/Stack'

import Hero from './Hero'
import Perks from './Perks'
import Members from './Members'
import RoadAhead from './RoadAhead'
import Term from './Term'
import Membership from '../../components/Membership'
import Newsletter from '../../components/Newsletter'

const MembershipPage = () => {
  return (
    <Stack>
      <Hero />
      <Perks />
      <Members />
      <RoadAhead />
      <Term />
      <Membership />
      <Newsletter />
    </Stack>
  )
}

export default MembershipPage
