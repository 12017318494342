import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const NotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/')
    }, 10000)

    return () => clearTimeout(timer)
  }, [navigate])

  return (
    <Box
      backgroundColor='white'
      sx={{
        height: 'calc(100vh - 12.374rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth='xl' disableGutters>
        <Stack direction='column' alignItems='center'>
          <Typography
            variant='h2'
            gutterBottom
            sx={{ textAlign: 'center', fontWeight: 600, maxWidth: '40rem' }}
          >
            Opps!
          </Typography>
          <Typography
            variant='h4'
            sx={{ textAlign: 'center', maxWidth: '40rem' }}
          >
            We can't seem to find the page you're looking for. Pardon us while
            we continue to build out our new site!
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export default NotFound
