import { useState, useEffect } from 'react'

import { functions } from '../../../firebase'
import { httpsCallable } from 'firebase/functions'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const redAstrix = {
  '& .MuiInputLabel-asterisk': { color: 'red' }
}

export default function MessageForm({ toggleShowMessageForm }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    recipientList: '',
    eventId: '',
    body: ''
  })
  const [hasFee, setHasFee] = useState(false)
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      questions
    }))
  }, [questions])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const dataToSubmit = {
      ...formData,
      datetime: new Date(formData.datetime),
      capacity: parseInt(formData.capacity, 10) || 0,
      maxPlusOnesPerRSVP: parseInt(formData.maxPlusOnesPerRSVP, 10) || 0,
      feeMembers: parseFloat(formData.feeMembers) || 0,
      feeNonMembers: parseFloat(formData.feeNonMembers) || 0
    }

    const createEvent = httpsCallable(functions, 'createEvent')
    const res = await createEvent(dataToSubmit)

    if (res.data.message) {
      toggleShowMessageForm()
    } else {
      setLoading(false)
      throw new Error('Something went wrong')
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const addQuestion = () => {
    setQuestions([...questions, { question: '', required: false }])
  }

  const removeQuestion = (indexToRemove) => {
    setQuestions(questions.filter((_, index) => index !== indexToRemove))
  }

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = questions.slice()
    newQuestions[index][field] = value
    setQuestions(newQuestions)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <form
        onSubmit={handleSubmit}
        style={{ width: isMobile ? '100%' : '50%' }}
      >
        <Stack spacing={2}>
          <Box>
            <Typography variant='h6'>Event Information</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Basic details about the event
            </Typography>
          </Box>
          <TextField
            name='title'
            label='Title'
            required
            sx={{ ...redAstrix }}
            value={formData.title}
            onChange={handleInputChange}
          />
          <TextField
            name='description'
            label='Description'
            required
            sx={{ ...redAstrix }}
            multiline
            rows={4}
            value={formData.description}
            onChange={handleInputChange}
          />
          <Box>
            <Typography variant='h6'>Upload Photos</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Please upload photos with the minimum required dimensions.
            </Typography>
          </Box>
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Location</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Venue details where the event will take place
            </Typography>
          </Box>
          <TextField
            name='locationName'
            label='Location Name'
            required
            sx={{ ...redAstrix }}
            value={formData.locationName}
            onChange={handleInputChange}
          />
          <TextField
            name='street'
            label='Street'
            value={formData.street}
            onChange={handleInputChange}
          />
          <TextField
            name='street2'
            label='Street 2'
            value={formData.street2}
            onChange={handleInputChange}
          />
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            justifyContent={'space-between'}
          >
            <TextField
              name='city'
              label='City'
              fullWidth
              value={formData.city}
              onChange={handleInputChange}
            />
            <TextField
              name='state'
              label='State'
              fullWidth
              value={formData.state}
              onChange={handleInputChange}
            />
          </Stack>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            justifyContent={'space-between'}
          >
            <TextField
              name='zipCode'
              label='Zip Code'
              fullWidth
              value={formData.zipCode}
              onChange={handleInputChange}
            />
            <TextField
              name='country'
              label='Country'
              fullWidth
              value={formData.country}
              onChange={handleInputChange}
            />
          </Stack>
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Additional Details</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Extra information regarding the event
            </Typography>
          </Box>
          <TextField
            name='datetime'
            label='Date & Time'
            type='datetime-local'
            required
            sx={{ ...redAstrix }}
            value={formData.datetime}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true
            }}
          />
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            justifyContent={'space-between'}
          >
            <TextField
              name='capacity'
              label='Capacity'
              type='number'
              value={formData.capacity}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              name='maxPlusOnesPerRSVP'
              label='Max Plus Ones per RSVP'
              type='number'
              value={formData.maxPlusOnesPerRSVP}
              onChange={handleInputChange}
              fullWidth
            />
          </Stack>
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Access and Fees</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Detail the access restrictions and applicable fees
            </Typography>
          </Box>
          <Stack direction={'row'} spacing={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name='membersOnly'
                  checked={formData.membersOnly}
                  onChange={handleInputChange}
                />
              }
              label='Members Only'
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='kidFriendly'
                  checked={formData.kidFriendly}
                  onChange={handleInputChange}
                />
              }
              label='Kid Friendly'
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='hasFee'
                  checked={hasFee}
                  onChange={(e) => {
                    handleInputChange(e)
                    setHasFee((prev) => !prev)
                  }}
                />
              }
              label='Has Fee'
            />
          </Stack>
          {hasFee && (
            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={2}
              justifyContent={'space-between'}
            >
              <TextField
                name='feeMembers'
                label='Fee for Members'
                type='number'
                value={formData.feeMembers}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                name='feeNonMembers'
                label='Fee for Non-Members'
                type='number'
                value={formData.feeNonMembers}
                onChange={handleInputChange}
                fullWidth
              />
            </Stack>
          )}
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Questionnaire</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Pre-event questionnaire for attendees
            </Typography>
          </Box>
          {questions.map((q, index) => (
            <Stack
              key={index}
              direction={'column'}
              alignItems={'flex-end'}
              spacing={2}
            >
              <TextField
                name='question'
                label='Question'
                required
                sx={{ ...redAstrix, flex: 1 }}
                value={q.question}
                onChange={(e) =>
                  handleQuestionChange(index, 'question', e.target.value)
                }
                fullWidth
              />
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={q.required}
                      onChange={() =>
                        handleQuestionChange(index, 'required', !q.required)
                      }
                    />
                  }
                  label={<Typography variant='body2'>Required</Typography>}
                />
                <Button
                  type='button'
                  variant='outlined'
                  color='error'
                  size='small'
                  onClick={() => removeQuestion(index)}
                >
                  Remove
                </Button>
              </Box>
            </Stack>
          ))}
          <Button
            type='button'
            variant='outlined'
            onClick={addQuestion}
            sx={{ marginTop: 2 }}
          >
            Add Question
          </Button>
          <Stack
            direction={'row'}
            spacing={2}
            sx={{ marginTop: '3rem !important' }}
          >
            <Button
              type='submit'
              variant='outlined'
              color='error'
              sx={{ width: '25%' }}
              onClick={toggleShowMessageForm}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              sx={{ width: '100%' }}
              loading={loading}
            >
              Create Event
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}
