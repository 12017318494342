import axios from 'axios'

interface MailingAddress {
  city: string
  country: string
  zipCode: string
  state: string
  street: string
  street2?: string
}

interface ValidationResult {
  status: 'success' | 'invalid_address' | 'system_error'
  data: MailingAddress | string
}

const validateAddress = async (
  mailingAddress: MailingAddress
): Promise<ValidationResult> => {
  const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`

  const body = {
    address: {
      revision: 0,
      regionCode: 'US',
      languageCode: 'en',
      postalCode: mailingAddress.zipCode,
      administrativeArea: mailingAddress.state,
      locality: mailingAddress.city,
      addressLines: [mailingAddress.street, mailingAddress.street2]
    }
  }

  try {
    const response = await axios.post(url, body)
    const result = response.data.result
    const verdict = result.verdict
    const hasUnconfirmedComponents = verdict.hasUnconfirmedComponents || false

    if (hasUnconfirmedComponents) {
      return {
        status: 'invalid_address',
        data: 'The provided address is not valid.'
      }
    }

    const addressComponents = response.data.result.address.addressComponents

    const formattedAddress = addressComponents.reduce(
      (acc: MailingAddress, component: any) => {
        const type = component.componentType
        const value = component.componentName.text

        switch (type) {
          case 'street_number':
          case 'route':
            acc.street = acc.street ? `${acc.street} ${value}` : value
            break
          case 'locality':
            acc.city = value
            break
          case 'administrative_area_level_1':
            acc.state = value
            break
          case 'postal_code':
            acc.zipCode = value
            break
          case 'postal_code_suffix':
            acc.zipCode = `${acc.zipCode}-${value}`
            break
          case 'country':
            acc.country = value
            break
          case 'subpremise':
            acc.street2 = value
            break
          default:
            break
        }

        return acc
      },
      {} as MailingAddress
    )

    return {
      status: 'success',
      data: formattedAddress
    }
  } catch (error) {
    console.error('Error during address validation:', error)
    return {
      status: 'system_error',
      data: 'A system error occurred during address validation.'
    }
  }
}

export default validateAddress
