import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

const button = {
  height: '2.5rem',
  color: 'black',
  borderColor: 'black',
  borderRadius: 0,
  minWidth: '7.813rem',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    borderColor: 'black'
  }
}

const Intro = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box backgroundColor='white'>
      <Container maxWidth='xl' disableGutters>
        <Stack
          spacing={2}
          sx={{
            padding: isMobile ? '2rem' : '4rem 8rem',
            backgroundColor: 'white'
          }}
        >
          <Typography variant='h4' align='center' sx={{ fontWeight: 600 }}>
            BOX BOX is a new kind of car club.
          </Typography>
          <Typography variant='body1' align='center'>
            A community-centered club that believes car culture should be for
            everyone, offering memorable in-person experiences and the right to
            collect our own shared fleet of cars worth driving.
          </Typography>
          <Typography
            variant='body1'
            align='center'
            sx={{ paddingTop: '0.5rem' }}
          >
            Soon we will have a clubhouse and garage for members to call home.
          </Typography>

          <Typography
            variant='h6'
            align='center'
            sx={{ fontWeight: 500, marginTop: '2.5rem !important' }}
          >
            Explore BOX BOX
          </Typography>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
          >
            <Link to='/membership' style={{ textDecoration: 'none' }}>
              <Button variant='outlined' size='large' sx={button}>
                Membership
              </Button>
            </Link>
            <Link to='/about' style={{ textDecoration: 'none' }}>
              <Button variant='outlined' size='large' sx={button}>
                About Us
              </Button>
            </Link>
            <Link to='/apply' style={{ textDecoration: 'none' }}>
              <Button variant='outlined' size='large' sx={button}>
                Apply
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Intro
