import { useState, useEffect } from 'react'
import { db } from '../../../firebase'
import { collection, getDocs, query } from 'firebase/firestore'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'

import People from '@mui/icons-material/People'
import Pending from '@mui/icons-material/Pending'
import OpenInNew from '@mui/icons-material/OpenInNew'
import Warning from '@mui/icons-material/Warning'

const Dashboard = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [metrics, setMetrics] = useState(false)

  const iconMap = {
    'OPEN APPLICATIONS': (
      <OpenInNew
        sx={{
          marginBottom: '2rem',
          fontSize: '3rem',
          opacity: 0.75,
          color: 'blue'
        }}
      />
    ),
    'PENDING MEMBERS': (
      <Pending
        sx={{
          marginBottom: '2rem',
          fontSize: '3rem',
          opacity: 0.75,
          color: 'red'
        }}
      />
    ),
    'ACTIVE MEMBERS': (
      <People
        sx={{
          marginBottom: '2rem',
          fontSize: '3rem',
          opacity: 0.75,
          color: 'green'
        }}
      />
    ),
    'PAST DUE MEMBERS': (
      <Warning
        sx={{
          marginBottom: '2rem',
          fontSize: '3rem',
          opacity: 0.75,
          color: 'orange'
        }}
      />
    )
  }

  const fetchDataAndCountStatus = async (collectionName, statuses) => {
    const col = collection(db, collectionName)
    const colQuery = query(col)
    const snapshot = await getDocs(colQuery)
    const docs = snapshot.docs.map((doc) => doc.data())

    const statusCounts = {}

    for (const status of statuses) {
      statusCounts[status] = docs.filter((doc) => doc.status === status).length
    }

    return statusCounts
  }

  useEffect(() => {
    const fetchData = async () => {
      const memberStatuses = ['Pending', 'Active', 'Past Due']
      const applicationStatuses = ['Open']

      const memberCounts = await fetchDataAndCountStatus(
        'members',
        memberStatuses
      )
      const applicationCounts = await fetchDataAndCountStatus(
        'applications',
        applicationStatuses
      )

      setMetrics([
        {
          label: 'OPEN APPLICATIONS',
          value: applicationCounts['Open']
        },
        {
          label: 'PENDING MEMBERS',
          value: memberCounts['Pending']
        },
        {
          label: 'ACTIVE MEMBERS',
          value: memberCounts['Active']
        },
        {
          label: 'PAST DUE MEMBERS',
          value: memberCounts['Past Due']
        }
      ])
    }

    fetchData()
  }, [])

  return (
    <Box
      backgroundColor='white'
      sx={{
        minHeight: 'calc(100vh - 12.374rem)',
        display: 'flex',
        paddingBottom: '2.5rem'
      }}
    >
      <Container maxWidth='xl'>
        <Stack
          direction='row'
          justifyContent={'space-between'}
          sx={{ marginBottom: 3 }}
        >
          <Typography variant='h4' sx={{ fontWeight: '500', color: '#18272F' }}>
            Dashboard
          </Typography>
        </Stack>
        <Grid container spacing={4}>
          {metrics &&
            metrics.map((metric, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    width: '100%',
                    minHeight: 200,
                    display: 'flex',
                    padding: isMobile ? '1rem' : '1rem 1.75rem'
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      sx={{ flexGrow: 1, height: '100%' }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          height: '100%'
                        }}
                      >
                        <Typography
                          variant='subtitle1'
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {metric.label}
                        </Typography>
                        <Typography variant='h3' sx={{ fontWeight: 'bold' }}>
                          {metric.value}
                        </Typography>
                      </Box>
                      {iconMap[metric.label]}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Dashboard
