import { useRef, useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'

import { functions, storage } from '../../../firebase'
import { httpsCallable } from 'firebase/functions'
import { ref, uploadBytesResumable } from 'firebase/storage'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const redAstrix = {
  '& .MuiInputLabel-asterisk': { color: 'red' }
}

export default function EventForm({ event, toggleShowEventForm }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    locationName: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    datetime: '',
    capacity: 0,
    maxPlusOnesPerRSVP: 0,
    membersOnly: false,
    kidFriendly: false,
    hasFee: false,
    feeMembers: 0,
    feeNonMembers: 0,
    externalUrl: '',
    questions: []
  })
  const [hasFee, setHasFee] = useState(false)
  const [questions, setQuestions] = useState([])
  const posterRef = useRef(null)
  const thumbnailRef = useRef(null)
  const [fileInputs, setFileInputs] = useState({
    poster: null,
    thumbnail: null
  })

  useEffect(() => {
    const initialFormData = {
      title: event.title,
      description: event.description,
      locationName: event.location.name,
      street: event.location.address.street,
      street2: event.location.address.street2,
      city: event.location.address.city,
      state: event.location.address.state,
      zipCode: event.location.address.zipCode,
      country: event.location.address.country,
      datetime: event.datetime
        ? dayjs(event.datetime).format('YYYY-MM-DDTHH:mm')
        : '',
      capacity: event.capacity,
      maxPlusOnesPerRSVP: event.maxPlusOnesPerRSVP,
      membersOnly: event.membersOnly,
      kidFriendly: event.kidFriendly,
      hasFee: event.hasFee,
      feeMembers: event.hasFee ? event.fee.members : 0,
      feeNonMembers: event.hasFee ? event.fee.nonMembers : 0,
      externalUrl: event.externalUrl,
      questions: event.questions
    }

    setHasFee(event.hasFee)
    setFormData(initialFormData)
  }, [event])

  const handleFileChange = (e, field, minWidth, minHeight) => {
    const file = e.target.files[0]
    if (!file) return

    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      const inputRef = field === 'poster' ? posterRef : thumbnailRef

      if (img.width < minWidth || img.height < minHeight) {
        inputRef.current.setCustomValidity(
          `Image dimensions should be at least ${minWidth}x${minHeight}`
        )
      } else {
        inputRef.current.setCustomValidity('')
      }

      inputRef.current.reportValidity()
    }

    setFileInputs((prev) => ({
      ...prev,
      [field]: file
    }))
  }

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      questions
    }))
  }, [questions])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const dataToSubmit = {
      ...formData,
      docId: event.docId,
      datetime: new Date(formData.datetime)
    }

    let hasUploadId = false
    const uploadId = uuid()

    if (fileInputs.poster) {
      const posterRef = ref(storage, `temp/${uploadId}/poster.png`)
      await uploadBytesResumable(posterRef, fileInputs.poster)
      hasUploadId = true
    }

    if (fileInputs.thumbnail) {
      const thumbnailRef = ref(storage, `temp/${uploadId}/thumbnail.png`)
      await uploadBytesResumable(thumbnailRef, fileInputs.thumbnail)
      hasUploadId = true
    }

    if (hasUploadId) {
      dataToSubmit.uploadId = uploadId
    }

    const editEvent = httpsCallable(functions, 'editEvent')
    const res = await editEvent(dataToSubmit)

    if (res.data.message) {
      toggleShowEventForm()
      setLoading(false)
    } else {
      setLoading(false)

      throw new Error('Something went wrong')
    }

    setLoading(false)
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  const addQuestion = () => {
    setQuestions([...questions, { question: '', required: false }])
  }

  const removeQuestion = (indexToRemove) => {
    setQuestions(questions.filter((_, index) => index !== indexToRemove))
  }

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = questions.slice()
    newQuestions[index][field] = value
    setQuestions(newQuestions)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <form
        onSubmit={handleSubmit}
        style={{ width: isMobile ? '100%' : '50%' }}
      >
        <Stack spacing={2}>
          <Box>
            <Typography variant='h6'>Event Information</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Basic details about the event
            </Typography>
          </Box>
          <TextField
            name='title'
            label='Title'
            required
            sx={{ ...redAstrix }}
            value={formData.title}
            onChange={handleInputChange}
          />
          <TextField
            name='description'
            label='Description'
            required
            sx={{ ...redAstrix }}
            multiline
            rows={4}
            value={formData.description}
            onChange={handleInputChange}
          />
          <Box>
            <Typography variant='h6'>Upload Photos</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Please upload photos with the minimum required dimensions.
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant='body2' gutterBottom>
              Thumbnails (At least 800 x 800)
            </Typography>
            <TextField
              type='file'
              fullWidth
              inputProps={{ accept: 'image/png' }}
              onChange={(e) => handleFileChange(e, 'thumbnail', 800, 800)}
              inputRef={thumbnailRef}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography variant='body2' gutterBottom>
              Poster (At least 800 x 1200)
            </Typography>
            <TextField
              type='file'
              fullWidth
              inputProps={{ accept: 'image/png' }}
              onChange={(e) => handleFileChange(e, 'poster', 800, 1200)}
              inputRef={posterRef}
            />
          </Box>
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Location</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Venue details where the event will take place
            </Typography>
          </Box>
          <TextField
            name='locationName'
            label='Location Name'
            required
            sx={{ ...redAstrix }}
            value={formData.locationName}
            onChange={handleInputChange}
          />
          <TextField
            name='street'
            label='Street'
            value={formData.street}
            onChange={handleInputChange}
          />
          <TextField
            name='street2'
            label='Street 2'
            value={formData.street2}
            onChange={handleInputChange}
          />
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            justifyContent={'space-between'}
          >
            <TextField
              name='city'
              label='City'
              fullWidth
              value={formData.city}
              onChange={handleInputChange}
            />
            <TextField
              name='state'
              label='State'
              fullWidth
              value={formData.state}
              onChange={handleInputChange}
            />
          </Stack>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            justifyContent={'space-between'}
          >
            <TextField
              name='zipCode'
              label='Zip Code'
              fullWidth
              value={formData.zipCode}
              onChange={handleInputChange}
            />
            <TextField
              name='country'
              label='Country'
              fullWidth
              value={formData.country}
              onChange={handleInputChange}
            />
          </Stack>
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Additional Details</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Extra information regarding the event
            </Typography>
          </Box>
          <TextField
            name='datetime'
            label='Date & Time'
            type='datetime-local'
            required
            sx={{ ...redAstrix }}
            value={formData.datetime}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true
            }}
          />
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            justifyContent={'space-between'}
          >
            <TextField
              name='capacity'
              label='Capacity'
              type='number'
              value={formData.capacity}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              name='maxPlusOnesPerRSVP'
              label='Max Plus Ones per RSVP'
              type='number'
              value={formData.maxPlusOnesPerRSVP}
              onChange={handleInputChange}
              fullWidth
            />
          </Stack>
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Access and Fees</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Detail the access restrictions and applicable fees
            </Typography>
          </Box>
          <Stack direction={'row'} spacing={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name='membersOnly'
                  checked={formData.membersOnly}
                  onChange={handleInputChange}
                />
              }
              label='Members Only'
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='kidFriendly'
                  checked={formData.kidFriendly}
                  onChange={handleInputChange}
                />
              }
              label='Kid Friendly'
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='hasFee'
                  checked={hasFee}
                  onChange={(e) => {
                    handleInputChange(e)
                    setHasFee((prev) => !prev)
                  }}
                />
              }
              label='Has Fee'
            />
          </Stack>
          {hasFee && (
            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={2}
              justifyContent={'space-between'}
            >
              <TextField
                name='feeMembers'
                label='Fee for Members'
                type='number'
                value={formData.feeMembers}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                name='feeNonMembers'
                label='Fee for Non-Members'
                type='number'
                value={formData.feeNonMembers}
                onChange={handleInputChange}
                fullWidth
              />
            </Stack>
          )}
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>External RSVP</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Enter external rsvp link to re-direct users
            </Typography>
          </Box>
          <TextField
            name='externalUrl'
            label='URL'
            fullWidth
            value={formData.externalUrl}
            onChange={handleInputChange}
          />
          <Box sx={{ marginTop: '2rem !important' }}>
            <Typography variant='h6'>Questionnaire</Typography>
            <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
              Pre-event questionnaire for attendees
            </Typography>
          </Box>
          {questions.map((q, index) => (
            <Stack
              key={index}
              direction={'column'}
              alignItems={'flex-end'}
              spacing={2}
            >
              <TextField
                name='question'
                label='Question'
                required
                sx={{ ...redAstrix, flex: 1 }}
                value={q.question}
                onChange={(e) =>
                  handleQuestionChange(index, 'question', e.target.value)
                }
                fullWidth
              />
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={q.required}
                      onChange={() =>
                        handleQuestionChange(index, 'required', !q.required)
                      }
                    />
                  }
                  label={<Typography variant='body2'>Required</Typography>}
                />
                <Button
                  type='button'
                  variant='outlined'
                  color='error'
                  size='small'
                  onClick={() => removeQuestion(index)}
                >
                  Remove
                </Button>
              </Box>
            </Stack>
          ))}
          <Button
            type='button'
            variant='outlined'
            onClick={addQuestion}
            sx={{ marginTop: 2 }}
          >
            Add Question
          </Button>
          <Stack
            direction={'row'}
            spacing={2}
            sx={{ marginTop: '3rem !important' }}
          >
            <Button
              type='submit'
              variant='outlined'
              color='error'
              sx={{ width: '25%' }}
              onClick={toggleShowEventForm}
            >
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              sx={{ width: '100%' }}
              loading={loading}
            >
              Update Event
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}
