import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'

const Newsletter = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [email, setEmail] = useState('')

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newsletterCollection = collection(db, 'newsletter')

    await addDoc(newsletterCollection, {
      email,
      created: serverTimestamp()
    })

    setEmail('')
  }

  return (
    <Box backgroundColor='white' sx={{ padding: isMobile ? '4rem' : '8rem' }}>
      <Container maxWidth='xl' disableGutters>
        <Stack direction='column' alignItems='center' spacing={4}>
          <Typography variant='h6' sx={{ fontWeight: 500 }}>
            DON'T MISS ANYTHING
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack direction={isMobile ? 'column' : 'row'}>
              <TextField
                value={email}
                onChange={handleChange}
                margin='none'
                size='small'
                placeholder='Enter email address'
                type='email'
                required
                sx={{
                  width: '18rem',
                  borderRadius: '0',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0',
                    '&.Mui-focused fieldset': {
                      borderColor: '#bdbdbd'
                    }
                  }
                }}
              />
              <Button
                type='submit'
                variant='outlined'
                size='large'
                sx={{
                  height: '2.5rem',
                  color: 'white',
                  borderColor: 'black',
                  backgroundColor: 'black',
                  borderRadius: 0,
                  marginTop: isMobile ? '1rem' : '0',
                  width: isMobile ? '18rem' : '8rem',
                  '&:hover': {
                    backgroundColor: '#bdbdbd',
                    borderColor: '#bdbdbd',
                    color: 'black',
                    fontWeight: 600
                  }
                }}
              >
                Sign Up
              </Button>
            </Stack>
          </form>
        </Stack>
      </Container>
    </Box>
  )
}

export default Newsletter
