import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../../../firebase'
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import VisibilityIcon from '@mui/icons-material/Visibility'

const Applications = () => {
  const navigate = useNavigate()
  const [rows, setRows] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const applicationCollection = collection(db, 'applications')
      const newApplicationsQuery = query(
        applicationCollection,
        where('status', '==', 'Open'),
        orderBy('created', 'asc')
      )
      const querySnapshot = await getDocs(newApplicationsQuery)
      const data = querySnapshot.docs.map((doc) => {
        const { created, phoneNumber, ...restData } = doc.data()
        const dateCreated = dayjs(created.toDate()).format('YYYY-MM-DD')
        return {
          id: doc.id,
          created: dateCreated,

          ...restData
        }
      })
      setRows(data)
    }

    fetchData()
  }, [])

  const exportToCSV = () => {
    if (rows.length === 0) {
      return
    }

    const flattenObject = (obj, parent = '') => {
      let result = {}
      for (let key in obj) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          const temp = flattenObject(obj[key], `${parent}${key}.`)
          result = { ...result, ...temp }
        } else if (Array.isArray(obj[key])) {
          result[`${parent}${key}`] = `"${JSON.stringify(obj[key]).replace(
            /"/g,
            '""'
          )}"`
        } else {
          result[`${parent}${key}`] = obj[key]
        }
      }
      return result
    }

    const flattenedRows = rows.map((row) => flattenObject(row))

    const headers = Object.keys(flattenedRows[0])

    const csvRows = flattenedRows.map((row) =>
      headers
        .map((header) => {
          const value = row[header]
          return typeof value === 'string' && value.includes(',')
            ? `"${value.replace(/"/g, '""')}"`
            : value
        })
        .join(',')
    )

    const csvData = [headers.join(','), ...csvRows].join('\n')

    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'applications.csv'
    a.click()
  }

  return (
    <Box
      backgroundColor='white'
      sx={{
        minHeight: 'calc(100vh - 12.374rem)',
        display: 'flex',
        paddingBottom: '2.5rem'
      }}
    >
      <Container maxWidth='xl'>
        <Stack direction='column' spacing={3}>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography
              variant='h4'
              sx={{ fontWeight: '500', color: '#18272F' }}
            >
              Applications
            </Typography>
            <Button
              variant='text'
              startIcon={<CloudDownloadIcon />}
              sx={{ color: '#18272F' }}
              onClick={exportToCSV}
            >
              Export
            </Button>
          </Stack>
          <TableContainer
            component={Paper}
            elevation={1}
            sx={{ overflowX: 'auto' }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: '#f9f9f9' }}>
                <TableRow>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Application Date
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    First Name
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Last Name
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Phone Number{' '}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      fontWeight: '600'
                    }}
                  >
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.created}</TableCell>
                    <TableCell>{row.name.first}</TableCell>
                    <TableCell>{row.name.last}</TableCell>
                    <TableCell>{row.contactInformation.phoneNumber}</TableCell>
                    <TableCell>{row.contactInformation.email}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Button
                        variant='text'
                        onClick={() =>
                          navigate(`/admin/applications/${row.id}`)
                        }
                        sx={{ color: '#18272F' }}
                      >
                        <VisibilityIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </Box>
  )
}

export default Applications
