import Stack from '@mui/material/Stack'

import Hero from './Hero'
import New from './New'
import Pillars from './Pillars'
import Membership from '../../components/Membership'
import Newsletter from '../../components/Newsletter'

const About = () => {
  return (
    <Stack>
      <Hero />
      <New />
      <Pillars />
      <Membership />
      <Newsletter />
    </Stack>
  )
}

export default About
