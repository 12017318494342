import { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

type WelcomeStepProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  memberInfo: {
    firstName: string
  }
}

const WelcomeStep: FC<WelcomeStepProps> = ({ setStep, memberInfo }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack direction={'column'}>
      <Typography
        variant='h4'
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          marginBottom: '2rem'
        }}
      >
        Welcome to BOXBOX, {memberInfo.firstName}!
      </Typography>
      <Typography variant='body1' paragraph>
        We're thrilled that you've been accepted into our community! Before we
        can make it official, there are just a couple of more steps to complete
        your membership.
      </Typography>
      <Typography variant='body1' paragraph>
        First up, we need to gather a bit more information from you. This helps
        us prepare your personalized welcome kit and set your preferences for
        future BoxBox merchandise.
      </Typography>

      <Typography variant='body1' paragraph sx={{ textAlign: 'left' }}>
        After that, you'll be prompted to enter your payment information for
        membership dues. Once that's done, you'll be a fully-fledged member of
        BoxBox!
      </Typography>

      <Typography variant='body1'>Let's get started, shall we?</Typography>
      <Button
        variant='outlined'
        size='large'
        type='submit'
        fullWidth
        onClick={() => setStep(2)}
        sx={{
          padding: isMobile ? '2rem' : '',
          marginTop: '1.5rem',
          marginBottom: '0rem',
          height: '2.5rem',
          color: 'white',
          borderColor: 'black',
          backgroundColor: 'black',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'white',
            color: 'black'
          }
        }}
      >
        Start Personalizing My Welcome Kit
      </Button>
    </Stack>
  )
}

export default WelcomeStep
