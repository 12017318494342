import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import icon from './icon.svg'

const Cars = () => {
  return (
    <Box
      backgroundColor='white'
      sx={{
        height: 'calc(100vh - 12.374rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth='xl' disableGutters>
        <Stack direction='column' alignItems='center'>
          <Box sx={{ marginBottom: '0.5rem' }}>
            <img src={icon} alt='Icon' width={'100%'} />
          </Box>
          <Typography
            variant='h4'
            sx={{ textAlign: 'center', fontWeight: 600, maxWidth: '40rem' }}
          >
            Car Fleet
          </Typography>
          <Typography
            variant='h6'
            sx={{ textAlign: 'center', maxWidth: '40rem' }}
          >
            We’re still working on this - join us and help us build the fleet
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export default Cars
