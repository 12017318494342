import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { db, functions } from '../../../firebase'
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'

const Application = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [application, setApplication] = useState(null)
  const [basicDetails, setBasicDetails] = useState(null)
  const [socials, setSocials] = useState(null)
  const [formResponses, setFormResponses] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [acceptDisable, setAcceptDisable] = useState(false)
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAction = async (action) => {
    try {
      const applicationRef = doc(db, 'applications', id)

      if (action === 'accept') {
        setAcceptDisable(true)
        const approveApplication = httpsCallable(
          functions,
          'approveApplication'
        )
        const res = await approveApplication({ applicationId: id })

        if (res.data.message) {
          setSnackbarMessage(res.data.message)
          setSnackbarSeverity('success')
        } else {
          throw new Error('Something went wrong')
        }
      } else {
        await updateDoc(applicationRef, {
          status: 'rejected',
          updated: serverTimestamp()
        })

        setSnackbarMessage('Application has been rejected.')
        setSnackbarSeverity('success')
      }

      setOpenSnackbar(true)
      setTimeout(() => {
        navigate('/admin/applications')
      }, 1500)
    } catch (error) {
      console.error(error)
      setSnackbarMessage(
        'An error occurred. Please try again or contact the admin.'
      )
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const applicationRef = doc(db, 'applications', id)
      const applicationSnap = await getDoc(applicationRef)

      if (applicationSnap.exists()) {
        const { created, phoneNumber, ...data } = applicationSnap.data()
        const dateCreated = dayjs(created.toDate()).format('YYYY-MM-DD')

        setApplication({
          id: applicationSnap.id,
          created: dateCreated,
          ...data
        })
      }
    }

    fetchData()
  }, [id])

  useEffect(() => {
    const details = [
      {
        label: 'Id',
        value: application?.id
      },
      {
        label: 'Date',
        value: application?.created
      },
      {
        label: 'Email',
        value: application?.contactInformation?.email
      },
      {
        label: 'Phone Number',
        value: application?.contactInformation?.phoneNumber
      }
    ]

    const socials = [
      {
        label: 'Linkedin',
        value: application?.socials?.linkedin
      },
      {
        label: 'Twitter',
        value: application?.socials?.twitter
      },
      {
        label: 'Instagram',
        value: application?.socials?.instagram
      },
      {
        label: 'Facebook',
        value: application?.socials?.facebook
      }
    ]

    setSocials(socials)
    setBasicDetails(details)
    setFormResponses(application?.responses)
  }, [application])

  return (
    <Box
      backgroundColor='white'
      sx={{
        minHeight: 'calc(100vh - 12.374rem)',
        display: 'flex',
        paddingBottom: '2.5rem'
      }}
    >
      <Container maxWidth='xl'>
        <Button
          variant='text'
          onClick={() => navigate(`/admin/applications`)}
          sx={{
            color: '#18272F',
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            marginBottom: '1.5rem'
          }}
        >
          <ArrowBackIcon
            fontSize='small'
            sx={{ paddingTop: '0.1rem', marginRight: '0.25rem' }}
          />
          <Typography variant='body1' sx={{ lineHeight: 'inherit' }}>
            Applications
          </Typography>
        </Button>
        <Stack
          direction='row'
          justifyContent={'space-between'}
          sx={{ marginBottom: '2.5rem' }}
        >
          <Typography
            variant={'h4'}
            sx={{
              fontWeight: '500',
              color: '#18272F'
            }}
          >
            {application?.name?.fullName}
          </Typography>
          <Box display='flex' alignItems='center'>
            <Button
              variant={isXsScreen ? 'text' : 'contained'}
              onClick={handleClick}
              sx={{ borderRadius: '0.75rem' }}
            >
              {!isXsScreen && (
                <Typography
                  variant='body1'
                  sx={{ color: 'white', fontWeight: '500' }}
                >
                  Actions
                </Typography>
              )}
              <KeyboardArrowDownIcon
                fontSize={isXsScreen ? 'large' : 'small'}
                sx={{
                  paddingTop: isXsScreen ? '0rem' : '0.2rem',
                  color: isXsScreen ? 'black' : 'inherit'
                }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleAction('accept')
                  handleClose()
                }}
                disabled={acceptDisable}
              >
                Accept
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAction('reject')
                  handleClose()
                }}
              >
                Reject
              </MenuItem>
            </Menu>
          </Box>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <Paper>
                <Typography
                  variant='h6'
                  sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
                >
                  Basic Details
                </Typography>
                <Stack spacing={2}>
                  {basicDetails &&
                    basicDetails.map((details) => {
                      return (
                        <Box key={details.label}>
                          <Typography
                            variant='body1'
                            sx={{
                              marginBottom: '0.25rem',
                              fontWeight: '500',
                              padding: '0 1rem'
                            }}
                          >
                            {details.label}
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{ marginBottom: '1rem', padding: '0 1rem' }}
                          >
                            {details.value}
                          </Typography>
                          <Divider />
                        </Box>
                      )
                    })}
                </Stack>
              </Paper>
              <Paper>
                <Typography
                  variant='h6'
                  sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
                >
                  Socials
                </Typography>
                <Stack spacing={2}>
                  {socials &&
                    socials.map((details) => {
                      return (
                        <Box key={details.label}>
                          <Typography
                            variant='body1'
                            sx={{
                              marginBottom: '0.25rem',
                              fontWeight: '500',
                              padding: '0 1rem'
                            }}
                          >
                            {details.label}
                          </Typography>
                          <Typography
                            variant='body2'
                            sx={{ marginBottom: '1rem', padding: '0 1rem' }}
                          >
                            {details.value}
                          </Typography>
                          <Divider />
                        </Box>
                      )
                    })}
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper>
              <Typography
                variant='h6'
                sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
              >
                Form Responses
              </Typography>
              <Stack spacing={2}>
                {formResponses &&
                  formResponses.map((responses) => {
                    return (
                      <Box key={responses.question}>
                        <Typography
                          variant='body1'
                          sx={{
                            marginBottom: '0.25rem',
                            fontWeight: '500',
                            padding: '0 1rem'
                          }}
                        >
                          {responses.question}
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            marginBottom: '1rem',
                            padding: '0 1rem',
                            fontStyle: responses.answer ? 'normal' : 'italic'
                          }}
                        >
                          {responses.answer
                            ? responses.answer
                            : 'No response provided'}
                        </Typography>
                        <Divider />
                      </Box>
                    )
                  })}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: '4rem' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Application
