import { useNavigate } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'

export default function MessagesTable({ rows }) {
  const navigate = useNavigate()

  return (
    <TableContainer component={Paper} elevation={1} sx={{ overflowX: 'auto' }}>
      <Table>
        <TableHead sx={{ backgroundColor: '#f9f9f9' }}>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
              Created
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
              Created By
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
              Recipient List
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
              Recipients
            </TableCell>
            <TableCell
              sx={{
                whiteSpace: 'nowrap',
                fontWeight: '600',
                textAlign: 'center'
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.datetime}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.title}</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {row.location.name}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {row.membersOnly ? 'Yes' : 'No'}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {row.hasFee ? 'Yes' : 'No'}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {row.capacity}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {row.status}
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {row.rsvps.length}
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <Button
                  variant='text'
                  onClick={() => navigate(`/admin/events/${row.id}`)}
                  sx={{ color: '#18272F' }}
                >
                  <VisibilityIcon />
                </Button>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
