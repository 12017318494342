import { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import InputMask from 'react-input-mask'
import CircularProgress from '@mui/material/CircularProgress'
import { db } from '../../firebase'
import { collection, query, where, getDocs } from 'firebase/firestore'

const ThankYouMessage = ({ title, subtitle }) => (
  <Box
    component={'div'}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 3,
      minHeight: '10rem'
    }}
  >
    <Typography variant='h4'>{title}</Typography>
    <Typography
      variant='h6'
      sx={{ fontWeight: 'normal', textAlign: 'center', maxWidth: '45rem' }}
    >
      {subtitle}
    </Typography>
  </Box>
)

export default function EventForm({
  handleSubmit,
  formData,
  formStatus,
  handleChange,
  event,
  loading,
  isMobile
}) {
  const [isMember, setIsMember] = useState(false)

  const handleVerifyMember = async (e) => {
    console.log('handleVerifyMember')
    const emailAddress = e.currentTarget.value.toLowerCase()
    if (!emailAddress) return

    const checkMembership = async (collectionName, fieldName) => {
      const collectionRef = collection(db, collectionName)
      const q = query(
        collectionRef,
        where(fieldName, '==', emailAddress),
        where('status', '==', 'Active')
      )
      const querySnapshot = await getDocs(q)
      return !querySnapshot.empty
    }

    const isMemberInMembers = await checkMembership(
      'members',
      'contactInformation.email'
    )
    const isMemberInHagertys = await checkMembership('hagertys', 'email')

    console.log(isMemberInMembers, isMemberInHagertys)

    setIsMember(isMemberInMembers || isMemberInHagertys)
  }

  const renderResponseBasedOnStatus = (status) => {
    if (status === 'loading') {
      return (
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 3,
            minHeight: '10rem'
          }}
        >
          <CircularProgress size='4rem' sx={{ color: 'gray', opacity: 0.25 }} />
        </Box>
      )
    }

    const getMessage = (status, eventId) => {
      switch (status) {
        case 'existing':
          return {
            title: 'Thank you for your enthusiasm!',
            subtitle:
              "It seems that you have already RSVP'd. We are eagerly looking forward to seeing you there."
          }
        case 'success': {
          const title = 'Thank you for RSVPing!'

          const subtitle =
            'We are thrilled to have you join us and look forward to celebrating car culture together!'

          return { title, subtitle }
        }
        case 'membersOnly':
          return {
            title: 'Members Only Event',
            subtitle:
              'This event is for members only. Please check your email address or consider joining us to attend exclusive events!'
          }
        default:
          return {
            title: 'Thank you for your enthusiasm!',
            subtitle:
              "It seems that you have already RSVP'd. We are eagerly looking forward to seeing you there."
          }
      }
    }

    if (['membersOnly', 'existing', 'success'].includes(status)) {
      return <ThankYouMessage {...getMessage(status, event.id)} />
    }

    return (
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Typography variant='h6'>Contact Information</Typography>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
            <TextField
              name='firstName'
              label='First Name'
              value={formData.firstName}
              onChange={handleChange}
              sx={{
                '& .MuiInputLabel-asterisk': { color: 'red' },
                flex: 1
              }}
            />
            <TextField
              name='lastName'
              label='Last Name'
              value={formData.lastName}
              onChange={handleChange}
              sx={{
                '& .MuiInputLabel-asterisk': { color: 'red' },
                flex: 1
              }}
            />
          </Stack>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
            <TextField
              name='emailAddress'
              label='Email Address'
              value={formData.emailAddress}
              onChange={handleChange}
              onBlur={handleVerifyMember}
              required
              sx={{
                '& .MuiInputLabel-asterisk': { color: 'red' },
                flex: 1
              }}
            />
            <InputMask
              mask='(999) 999-9999'
              value={formData.phoneNumber}
              onChange={handleChange}
            >
              {() => (
                <TextField
                  name='phoneNumber'
                  label='Phone Number'
                  required
                  sx={{
                    '& .MuiInputLabel-asterisk': { color: 'red' },
                    flex: 1
                  }}
                />
              )}
            </InputMask>
          </Stack>
          {event.maxPlusOnesPerRSVP > 0 && (
            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={2}
              sx={{
                width: isMobile ? '100%' : '50%',
                paddingRight: isMobile ? 0 : '8px'
              }}
            >
              <TextField
                type='number'
                name='plusOnes'
                label='Plus Ones'
                value={formData.plusOnes}
                onChange={handleChange}
                inputProps={{
                  max: event.maxPlusOnesPerRSVP,
                  min: 0
                }}
                sx={{
                  '& .MuiInputLabel-asterisk': { color: 'red' },
                  flex: 1
                }}
              />
            </Stack>
          )}
          {event.questions && (
            <>
              <Typography variant='h6'>Addtional Questions</Typography>
              {event.questions.map((question, index) => {
                return (
                  <TextField
                    key={question.question}
                    name={`questions[${index}].answer`}
                    label={question.question}
                    value={
                      formData.answers ? formData.answers[index].answer : ''
                    }
                    onChange={handleChange}
                    required={question.required}
                    sx={{
                      '& .MuiInputLabel-asterisk': { color: 'red' },
                      flex: 1
                    }}
                  />
                )
              })}
            </>
          )}
          {event.hasFee && isMember && (
            <Box
              component={'div'}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography
                variant='body2'
                color='primary'
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  maxWidth: '25rem'
                }}
              >
                Member rate applied!
              </Typography>
            </Box>
          )}
          <Box
            component={'div'}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              sx={{ width: '100%', maxWidth: '25rem' }}
              loading={loading}
            >
              {event.hasFee ? 'Continue to Payment' : 'Send RSVP'}
            </LoadingButton>
          </Box>
        </Stack>
      </form>
    )
  }

  return <>{renderResponseBasedOnStatus(formStatus)}</>
}
