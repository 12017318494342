import { FC, useEffect } from 'react'
import { updateDoc, doc, serverTimestamp } from 'firebase/firestore'
import { db } from '../../firebase'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

const ConfirmationStep: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { id: stripeCheckoutId } = useParams()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const registrationDocId = params.get('utm_content')

    if (registrationDocId) {
      const registrationRef = doc(db, 'registrations', registrationDocId)

      updateDoc(registrationRef, {
        stripeCheckoutId,
        updated: serverTimestamp()
      })
        .then(() => {
          setTimeout(() => {
            navigate(`/`)
          }, 20000)
        })
        .catch((error) => {
          console.error('Error updating document: ', error)
          navigate(`/`)
        })
    }
  }, [stripeCheckoutId, location, navigate])

  return (
    <Stack direction='column' alignItems={isMobile ? 'stretch' : 'left'}>
      <Typography
        variant='h4'
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          marginBottom: '2rem'
        }}
      >
        Welcome to BOXBOX!
      </Typography>
      <Typography variant='body1'>
        Thank you for completing your membership payment. We're thrilled to have
        you as a part of our community!
      </Typography>
      <Typography variant='body1' sx={{ marginTop: '2.5rem' }}>
        What's Next?
      </Typography>
      <List>
        <ListItem
          sx={{ '&::before': { content: '"- "', marginRight: '1rem' } }}
        >
          <Typography variant='body1'>
            You'll be receiving a welcome email shortly with more details about
            your membership.
          </Typography>
        </ListItem>
        <ListItem
          sx={{ '&::before': { content: '"- "', marginRight: '1rem' } }}
        >
          <Typography variant='body1'>
            The email will include key contacts for any questions or concerns
            you may have.
          </Typography>
        </ListItem>
        <ListItem
          sx={{ '&::before': { content: '"- "', marginRight: '1rem' } }}
        >
          <Typography variant='body1'>
            You'll also find an invitation link to join our exclusive
            members-only WhatsApp channel where you can meet other members and
            stay updated on club news.
          </Typography>
        </ListItem>
      </List>
      <Typography variant='body1' sx={{ marginTop: '2rem' }}>
        If you don't receive the email or have any questions, feel free to reach
        out to us.
      </Typography>
      <Typography variant='body1'>
        We can't wait to see you at our next event!
      </Typography>
    </Stack>
  )
}

export default ConfirmationStep
