import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

import aboutOne from '../../assets/about_one.png'
import aboutTwo from '../../assets/about_two.png'
import aboutThree from '../../assets/about_three.png'
import aboutFour from '../../assets/about_four.png'

const About = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const sections = [
    {
      headline: 'DEMOCRATIZING ACCESS TO CAR CULTURE',
      body: 'We are a club that wants to make it easier for more people to experience and enjoy car and motorosports culture with unique events and opportunities',
      button: 'Discover More',
      image: aboutOne,
      to: '/membership'
    },
    {
      headline: 'DRIVEN PEOPLE',
      body: 'We are a collective of the driven, a group of passionate individuals with different ambitions and aspirations, who come together over a shared love of all things automotive. ',
      button: 'Our Vision',
      image: aboutTwo,
      to: '/about'
    },
    {
      headline: 'CROWD COLLECTING UNIQUE CARS',
      body: 'We embrace the unconventional and the one of a kind. We are redefining vintage car collecting by bringing our resources together to curate a fleet of amazing cars worth driving #NOGARAGEQUEENS',
      button: 'Discover More',
      image: aboutThree,
      to: '/cars'
    },
    {
      headline: 'SHARED SPACE',
      body: 'Building a safe space for collaboration - today it’s virtual, tomorrow we’ll have a physical space for members to come together, connect and thrive.',
      button: false,
      image: aboutFour
    }
  ]

  return (
    <Box
      backgroundColor='#F2F1F1'
      sx={{ paddingTop: isMobile ? '0.5rem' : '2rem' }}
    >
      <Container maxWidth='xl' disableGutters>
        <Stack>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'start'}
            alignItems={'center'}
            spacing={isMobile ? 3 : 6}
            sx={{
              borderBottom: '1px black solid',
              padding: '1rem'
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 500, lineHeight: 0.8 }}>
              {isMobile ? (
                <>MORE THAN CARS</>
              ) : (
                <>
                  MORE THAN<br></br>CARS
                </>
              )}
            </Typography>
          </Stack>
          {sections.map((section, index) => {
            return (
              <Stack
                direction={
                  isTablet ? 'column' : index % 2 === 0 ? 'row-reverse' : 'row'
                }
                sx={{ width: '100%' }}
                key={section.headline}
              >
                <Box
                  sx={{
                    width: isTablet ? '100%' : '60%',
                    padding: isTablet ? '1rem' : '2rem'
                  }}
                >
                  <Box
                    sx={{
                      height: 0,
                      paddingBottom: '60%', // For 16:9 aspect ratio - adjust as required
                      backgroundImage: `url(${section.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                </Box>
                <Stack
                  direction='column'
                  justifyContent={'end'}
                  spacing={isTablet ? 2 : 6}
                  sx={{
                    width: isTablet ? '100%' : '40%',
                    padding: isTablet ? '1rem' : '2rem'
                  }}
                >
                  <Typography
                    variant='h5'
                    sx={{
                      fontWeight: 900,
                      fontSize: '2rem',
                      lineHeight: 0.9,
                      letterSpacing: 3
                    }}
                  >
                    {section.headline}
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ lineHeight: 1, fontSize: '1.25rem' }}
                  >
                    {section.body}
                  </Typography>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={() => navigate(section.to)}
                    sx={{
                      color: 'black',
                      borderColor: 'black',
                      borderRadius: 0,
                      width: '10rem',
                      marginBottom: '0.5rem',
                      visibility: section.button ? 'visibile' : 'hidden',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        borderColor: 'black'
                      }
                    }}
                  >
                    {section.button}
                  </Button>
                </Stack>
              </Stack>
            )
          })}
        </Stack>
      </Container>
    </Box>
  )
}

export default About
