import { FC, useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getDoc, doc, Timestamp, DocumentReference } from 'firebase/firestore'
import { db } from '../../firebase'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import WelcomeStep from './WelcomeStep'
import PreferenceForm from './PreferenceForm'
import PaymentGateway from './PaymentGateway'
import ConfirmationStep from './ConfirmationStep'

interface MemberInfoData {
  firstName: string
  email: string
  encodedEmail: string
}

interface RegistrationData {
  apparel?: {
    hatStyle: 'Flat Brim' | 'Trucker'
    shirtSize: 'X-Small' | 'Small' | 'Medium' | 'Large' | 'X-Large' | 'XX-Large'
  }
  contactInformation?: {
    mailingAddress: {
      city: string
      country: string
      state: string
      street: string
      street2: string
      zipCode: string
    }
  }
  favoriteCar?: string
  updated?: Timestamp
  created: Timestamp
  memberRef: DocumentReference
}

const Registration: FC = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { id } = useParams()
  const location = useLocation()
  const [step, setStep] = useState<number>(0)
  const [registrationData, setRegistrationData] =
    useState<RegistrationData>(null)
  const [memberInfo, setMemberInfo] = useState<MemberInfoData>(null)

  useEffect(() => {
    const fetchRegistrationAndMember = async () => {
      const params = new URLSearchParams(location.search)
      const utmContent = params.get('utm_content')

      let fetchId: string

      if (utmContent) {
        fetchId = utmContent
        setStep(4)
      } else {
        fetchId = id
        setStep(1)
      }

      const registrationDoc = await getDoc(doc(db, 'registrations', fetchId))
      if (registrationDoc.exists()) {
        const data = registrationDoc.data() as RegistrationData
        setRegistrationData(data)
        const memberDoc = await getDoc(data.memberRef)
        if (memberDoc.exists()) {
          const data = memberDoc.data() as {
            name: { first: string }
            contactInformation: { email: string }
          }
          const firstName = data.name.first
          const email = data.contactInformation.email
          const encodedEmail = encodeURIComponent(email)
          setMemberInfo({ email, encodedEmail, firstName })
        }
      } else {
        navigate('/')
      }
    }

    fetchRegistrationAndMember()
  }, [id, location, navigate])

  const renderCurrentStep = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return <WelcomeStep setStep={setStep} memberInfo={memberInfo} />
      case 2:
        return (
          <PreferenceForm
            setStep={setStep}
            id={id}
            registrationData={registrationData}
          />
        )
      case 3:
        return <PaymentGateway id={id} memberInfo={memberInfo} />
      case 4:
        return <ConfirmationStep />
      default:
        return (
          <Stack direction='column' alignItems={isMobile ? 'strech' : 'center'}>
            <CircularProgress
              sx={{ marginTop: '10rem', color: 'black', opacity: '0.75' }}
            />
          </Stack>
        )
    }
  }

  return (
    <>
      {memberInfo && (
        <Box
          bgcolor='white'
          sx={{
            minHeight: 'calc(100vh - 12.374rem)',
            display: 'flex',
            alignItems: 'top',
            justifyContent: 'center'
          }}
        >
          <Container
            maxWidth='xl'
            disableGutters
            sx={{
              padding: isMobile ? '2rem 1rem' : '4rem 0',
              maxWidth: '40rem'
            }}
          >
            {renderCurrentStep(step)}
          </Container>
        </Box>
      )}
    </>
  )
}

export default Registration
