import { db } from '../../firebase'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useState, FormEvent } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

interface FormData {
  name: {
    first: string
    last: string
    fullName: string
  }
  contactInformation: {
    email: string
    phoneNumber: string
  }
  socials: {
    linkedin: string
    twitter: string
    instagram: string
    facebook: string
  }
  responses: Array<{
    question: string
    answer: string
  }>
  status: string
}

const Form = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [industries] = useState<string[]>([
    'Accounting',
    'Advertising',
    'Angel Investment',
    'Automotive',
    'Banking',
    'Consulting',
    'Consumer Goods',
    'Education',
    'Energy',
    'Entertainment',
    'Entrepreneurship',
    'Finance',
    'Food and Beverage',
    'Healthcare',
    'Information Technology',
    'Insurance',
    'Investments',
    'Manufacturing',
    'Marketing',
    'Media',
    'Nonprofit',
    'Pharmaceuticals',
    'Real Estate',
    'Retail',
    'Startups',
    'Tech Consulting',
    'Telecommunications',
    'Tourism',
    'Transportation',
    'Venture Capital',
    'Other'
  ])
  const [blankForm] = useState<FormData>({
    name: {
      first: '',
      last: '',
      fullName: ''
    },
    contactInformation: {
      email: '',
      phoneNumber: ''
    },
    socials: {
      linkedin: '',
      twitter: '',
      instagram: '',
      facebook: ''
    },
    responses: [
      { question: 'Were you referred to BoxBox by someone?', answer: '' },
      { question: "What's your current industry?", answer: '' },
      {
        question:
          'How did you first become interested in cars or racing, and how has your passion grown?',
        answer: ''
      },
      {
        question: 'What are you most eager about in becoming part of BoxBox?',
        answer: ''
      },
      {
        question: "Any goals or projects you'd like to achieve with BoxBox?",
        answer: ''
      }
    ],
    status: 'Open'
  })
  const [formData, setFormData] = useState<FormData>(blankForm)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value
    const name = e.target.name.split('.')

    if (name?.length === 1) {
      setFormData({ ...formData, [name[0]]: value })
    } else if (name?.length === 2) {
      setFormData({
        ...formData,
        [name[0]]: {
          ...formData[name[0]],
          [name[1]]: value
        }
      })
    } else if (name?.length === 3) {
      const idx = parseInt(name[1])
      const answer = name[2]
      const newResponses = [...formData.responses]
      newResponses[idx][answer] = value as string
      setFormData({ ...formData, responses: newResponses })
    }
  }

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const syntheticEvent = {
      target: {
        name: e.target.name || '',
        value: e.target.value
      }
    } as React.ChangeEvent<HTMLInputElement>

    handleChange(syntheticEvent)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const applicationData = { ...formData, created: serverTimestamp() }
    applicationData.name.fullName = `${applicationData.name.first} ${applicationData.name.last}`
    const applicationDoc = collection(db, 'applications')
    await addDoc(applicationDoc, applicationData)

    setFormData(blankForm)

    setSubmitted(true)
  }

  return (
    <>
      {submitted ? (
        <Stack
          direction='column'
          alignItems='center'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography
            variant='h4'
            gutterBottom
            sx={{
              textAlign: 'center',
              fontWeight: 600,
              marginBottom: '2rem'
            }}
          >
            Thank You!
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            sx={{
              textAlign: 'center',
              marginBottom: '2rem',
              maxWidth: '25rem'
            }}
          >
            Thank you for submitting your application. We appreciate your
            interest and will reach out to you shortly with next steps. Please
            check your provided contact details for updates.
          </Typography>
        </Stack>
      ) : (
        <Stack direction='column' alignItems={isMobile ? 'strech' : 'center'}>
          <Typography
            variant='h4'
            gutterBottom
            sx={{
              textAlign: 'center',
              fontWeight: 600,
              marginBottom: '2rem'
            }}
          >
            Member Application
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Box>
                <Typography variant='h6'>Contact Information</Typography>
                <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
                  We keep your contact information private and will seek your
                  consent for any future sharing
                </Typography>
              </Box>
              <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                <TextField
                  name='name.first'
                  label='First Name'
                  value={formData.name.first}
                  onChange={handleChange}
                  required
                  sx={{
                    '& .MuiInputLabel-asterisk': { color: 'red' },
                    flex: 1
                  }}
                />
                <TextField
                  name='name.last'
                  label='Last Name'
                  value={formData.name.last}
                  onChange={handleChange}
                  required
                  sx={{
                    '& .MuiInputLabel-asterisk': { color: 'red' },
                    flex: 1
                  }}
                />
              </Stack>
              <TextField
                name='contactInformation.email'
                label='Email'
                value={formData.contactInformation.email}
                onChange={handleChange}
                required
                sx={{ '& .MuiInputLabel-asterisk': { color: 'red' } }}
              />
              <TextField
                name='contactInformation.phoneNumber'
                label='Phone Number'
                value={formData.contactInformation.phoneNumber}
                onChange={handleChange}
                required
                sx={{ '& .MuiInputLabel-asterisk': { color: 'red' } }}
              />
              <Box>
                <Typography variant='h6'>Social Media Profiles</Typography>
                <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
                  We kindly request you to include at least one social media
                  profile link
                </Typography>
              </Box>
              <TextField
                name='socials.linkedin'
                label='Linkedin'
                value={formData.socials.linkedin}
                onChange={handleChange}
              />
              <TextField
                name='socials.twitter'
                label='Twitter'
                value={formData.socials.twitter}
                onChange={handleChange}
              />
              <TextField
                name='socials.instagram'
                label='Instagram'
                value={formData.socials.instagram}
                onChange={handleChange}
              />
              <TextField
                name='socials.facebook'
                label='Facebook'
                value={formData.socials.facebook}
                onChange={handleChange}
              />
              <div>
                <Typography variant='h6'>A Closer Look at You</Typography>
                <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
                  Help us get to know you better, answer a few questions to
                  understand your passion
                </Typography>
              </div>
              <div>
                <Typography variant='body1' gutterBottom>
                  {formData.responses[0].question + ' '}
                  <Box component={'span'} sx={{ color: 'red' }}>
                    *
                  </Box>
                </Typography>
                <TextField
                  name={`responses.0.answer`}
                  required
                  fullWidth
                  multiline
                  rows={2}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: false,
                    style: { visibility: 'hidden' }
                  }}
                />
              </div>
              <div>
                <Typography variant='body1' gutterBottom>
                  {formData.responses[1].question + ' '}
                  <Box component={'span'} sx={{ color: 'red' }}>
                    *
                  </Box>
                </Typography>
                <FormControl required fullWidth>
                  <Select
                    name={`responses.1.answer`}
                    value={formData.responses[1].answer}
                    onChange={handleSelectChange}
                    required
                  >
                    {industries.map((industry, index) => (
                      <MenuItem value={industry} key={index}>
                        {industry}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Typography variant='body1' gutterBottom>
                  {formData.responses[2].question + ' '}
                  <Box component={'span'} sx={{ color: 'red' }}>
                    *
                  </Box>
                </Typography>
                <TextField
                  name={`responses.2.answer`}
                  required
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: false,
                    style: { visibility: 'hidden' }
                  }}
                />
              </div>
              <div>
                <Typography variant='body1' gutterBottom>
                  {formData.responses[3].question + ' '}
                  <Box component={'span'} sx={{ color: 'red' }}>
                    *
                  </Box>
                </Typography>
                <TextField
                  name={`responses.3.answer`}
                  required
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: false,
                    style: { visibility: 'hidden' }
                  }}
                />
              </div>
              <div>
                <Typography variant='body1' gutterBottom>
                  {formData.responses[4].question}
                </Typography>
                <TextField
                  name={`responses.4.answer`}
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: false,
                    style: { visibility: 'hidden' }
                  }}
                />
                <Typography
                  variant='subtitle2'
                  sx={{ fontStyle: 'italic', fontWeight: 'normal' }}
                >
                  Your response is optional but valued. Whether it's racing in
                  Dakar or starting a podcast, we're open to helping you realize
                  your ideas.
                </Typography>
              </div>
              <Button
                variant='outlined'
                size='large'
                type='submit'
                sx={{
                  marginTop: '2.5rem !important',
                  marginBottom: '0rem',
                  height: '2.5rem',
                  color: 'white',
                  borderColor: 'black',
                  backgroundColor: 'black',
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'black'
                  }
                }}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Stack>
      )}
    </>
  )
}

export default Form
