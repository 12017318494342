import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const Login = () => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('utm_term')

    const updatePaymentStatus = async () => {
      const eventDocRef = doc(db, 'events', id)
      await updateDoc(eventDocRef, { paid: true })
    }

    updatePaymentStatus()
  }, [location])

  return (
    <Box
      backgroundColor='white'
      sx={{
        height: 'calc(100vh - 12.374rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth='xl' disableGutters>
        <Stack
          direction='column'
          alignItems='center'
          sx={{
            minHeight: 'calc(100vh - 15rem)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem'
          }}
        >
          <Typography
            variant='h4'
            gutterBottom
            sx={{
              textAlign: 'center',
              fontWeight: 600,
              marginBottom: '2rem'
            }}
          >
            Thank You!
          </Typography>
          <Typography
            variant='body1'
            gutterBottom
            sx={{
              textAlign: 'center',
              marginBottom: '2rem',
              maxWidth: '25rem'
            }}
          >
            Thank you for submitting your payment. Your slot for the event has
            been confirmed. We look forward to seeing you there! Please check
            your provided contact details for any updates or additional
            information regarding the event.
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export default Login
