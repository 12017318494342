import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import icon from './icon.svg'

const Login = () => {
  const navigate = useNavigate()
  const [bypass, setBypass] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const bypassLogin = () => {
    setBypass(true)
  }

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        navigate('/')
      })
      .catch((error) => {
        console.error('Login error:', error)
        setErrorMessage(
          "Your username or password don't match. Please try again or contact someone for help."
        )
      })
  }

  const closedSection = (
    <Stack direction='column' alignItems='center'>
      <Box sx={{ marginBottom: '0.5rem' }}>
        <img src={icon} alt='Icon' width={'100%'} />
      </Box>
      <Typography
        variant='h4'
        sx={{ textAlign: 'center', fontWeight: 600, maxWidth: '40rem' }}
      >
        Members Section
      </Typography>
      <Typography variant='h6' sx={{ textAlign: 'center', maxWidth: '40rem' }}>
        Coming Soon
      </Typography>
    </Stack>
  )

  const loginBypass = (
    <Stack direction='column' alignItems='center' spacing={2}>
      <TextField
        label='Username'
        variant='outlined'
        size='small'
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ width: '20rem' }}
      />
      <TextField
        label='Password'
        type='password'
        variant='outlined'
        size='small'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ width: '20rem' }}
      />
      {errorMessage && (
        <Typography variant='body2' color='error'>
          {errorMessage}
        </Typography>
      )}
      <Button variant='contained' color='primary' onClick={handleLogin}>
        Login
      </Button>
    </Stack>
  )

  return (
    <Box
      backgroundColor='white'
      onDoubleClick={bypassLogin}
      sx={{
        height: 'calc(100vh - 12.374rem)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth='xl' disableGutters>
        {bypass ? loginBypass : closedSection}
      </Container>
    </Box>
  )
}

export default Login
