import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const Term = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      backgroundColor='#a5a3a3'
      sx={{ padding: isMobile ? '1rem' : '1.5rem 0' }}
    >
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          padding: isMobile ? '0' : '0 2.5rem',
          width: '100%'
        }}
      >
        <Stack justifyContent='center' alignItems='center'>
          <Typography
            variant='h6'
            sx={{
              color: 'white',
              fontWeight: '500',
              lineHeight: 1.1,
              letterSpacing: 0.5,
              maxWidth: '53.625rem'
            }}
          >
            OUR LONG TERM VISION IS TO TAKE BOX BOX FROM A PHYSICAL MEMBERS
            CLUBHOUSE IN MIAMI TO A GLOBAL COLLECTIVE OF THE DRIVEN. ALL
            TOGETHER IN PURSUIT OF THE LIMIT.
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export default Term
