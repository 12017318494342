import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import phaseOne from '../../assets/phase_one.png'
import phaseTwo from '../../assets/phase_two.png'
import phaseThree from '../../assets/phase_three.png'

const RoadAhead = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const phases = [
    {
      title: 'PHASE 1',
      headline: (
        <>
          BUILD COMMUNITY
          <br />
          AROUND EPIC EVENTS
        </>
      ),
      body: "From watch parties to behind-the-scenes tours of private Porsche collections, and even intimate dinners with F1 insiders, we're committed to building a vibrant community through extraordinary member experiences.",
      points: [
        '- 2 epic events each month, at minimum',
        '- Online community forum',
        '- Members only merch'
      ],
      img: phaseOne,
      alt: 'Women wearing sunglasses in Porsche jacket walking in fron of cars'
    },
    {
      title: 'PHASE 2',
      headline: (
        <>
          CROWD-COLLECT CARS TO LAUNCH
          <br />A FLEET OF SHARED VEHICLES
        </>
      ),
      body: 'We embrace the unconventional and appreciate the beauty of unique and interesting cars. Our focus is on curating a collection of cars that are truly worth driving, avoiding mainstream supercars and vehicles manufactured within the last decade.',
      points: [
        '- Members will work together to curate collection of cars worth driving',
        '- Every vote counts!',
        '- Booking system will allow members to reserve cars'
      ],
      img: phaseTwo,
      alt: 'Garage of collectabile cars, one hidden car with red cover on it'
    },
    {
      title: 'PHASE 3',
      headline: (
        <>
          GET PHYSICAL WITH THE LAUNCH
          <br />
          OF OUR FIRST CLUBHOUSE IN MIAMI
        </>
      ),
      body: 'The BOX BOX goal is to create a safe and inclusive space where members can connect, collaborate on exciting projects, and explore new ideas. Our Miami BOX BOX hub will include much more than cars.',
      points: [
        '- Real world members clubhouse',
        '- 24/7 Access to a unique space where members can hang, play or work',
        '- Grab a bite or a drink, mix and mingle IRL while catching races',
        '- Car storage and car sharing'
      ],
      img: phaseThree,
      alt: 'Photograph session with vintage car'
    }
  ]

  return (
    <Box
      backgroundColor='white'
      sx={{ paddingTop: '3rem', paddingBottom: isMobile ? '1rem' : '5rem' }}
    >
      <Container maxWidth='xl' disableGutters>
        <Stack>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent={'start'}
            alignItems={'center'}
            spacing={isMobile ? 3 : 6}
            sx={{
              borderBottom: '1px black solid',
              padding: '1rem'
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 500, lineHeight: 0.8 }}>
              {isMobile ? (
                <>THE ROAD AHEAD</>
              ) : (
                <>
                  THE ROAD<br></br>AHEAD
                </>
              )}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            sx={{ marginTop: '2.5rem', padding: isMobile ? '0 1rem' : '3rem' }}
          >
            <Stack
              sx={{ marginRight: '2rem', display: isMobile ? 'none' : '' }}
            >
              <Box
                width='100%'
                height='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Box backgroundColor='black' width='2px' height='100%' />
              </Box>
              <Box
                left='50%'
                bottom='0'
                transform='translateX(-50%)'
                width='0'
                height='0'
                borderTop='10px solid black'
                borderLeft='5px solid transparent'
                borderRight='5px solid transparent'
              />
            </Stack>
            <Stack spacing={8}>
              {phases.map((phase, index) => (
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  key={index}
                  spacing={isMobile ? 4 : 0}
                >
                  <Stack
                    spacing={2}
                    sx={{
                      width: isMobile ? '100%' : '40%',
                      paddingRight: isMobile ? '0' : '4rem'
                    }}
                  >
                    <Typography
                      variant='h5'
                      sx={{
                        fontSize: '1.75rem',
                        fontWeight: '800'
                      }}
                    >
                      {phase.title}
                    </Typography>
                    <Typography variant='h6' sx={{ lineHeight: '1' }}>
                      {phase.headline}
                    </Typography>
                    <Typography variant='body2'>{phase.body}</Typography>
                    <ul style={{ paddingLeft: '0' }}>
                      {phase.points.map((point, index) => (
                        <Typography variant='body2' key={index}>
                          {point}
                        </Typography>
                      ))}
                    </ul>
                  </Stack>
                  <Box sx={{ width: isMobile ? '100%' : '60%' }}>
                    <img src={phase.img} alt={phase.alt} width={'100%'} />
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default RoadAhead
