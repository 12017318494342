import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CircleIcon from '@mui/icons-material/Circle'

const Members = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      backgroundColor='#F2F1F1'
      sx={{ paddingTop: '3rem', paddingBottom: isMobile ? '2rem' : '5rem' }}
    >
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          padding: isMobile ? '0 1rem' : '0 2.5rem',
          width: '100%'
        }}
      >
        <Stack
          height='100%'
          direction={isMobile ? 'column' : 'row'}
          justifyContent={'space-between'}
        >
          <Box sx={{ width: isMobile ? '100%' : '70%', paddingRight: '4rem' }}>
            <Typography variant='h4' sx={{ marginBottom: '2rem' }}>
              What drives us
            </Typography>
            <Typography variant='body2'>
              BOX BOX isn't your typical car club. We're a community of
              passionate individuals with diverse interests that intersect art,
              entertainment, racing, technology, and more.
            </Typography>
            <Typography
              variant='body2'
              sx={{
                marginTop: '.75rem',
                marginBottom: '.75rem',
                fontWeight: 600
              }}
            >
              DISCOVER OUR PILLARS OF CONNECTION:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <Typography variant='body2'>
                  Cool, quirky, vintage and one of kind cars
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <Typography variant='body2'>
                  Motorsports and Formula 1
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <Typography variant='body2'>
                  Future of Mobility Arts and Entertainment
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <Typography variant='body2'>
                  Increasing Diversity in Motorsports
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <Typography variant='body2'>Tech Forward</Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <Typography variant='body2'>Business of Motorsports</Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon sx={{ fontSize: '10px' }} />
                </ListItemIcon>
                <Typography variant='body2'>
                  Amateur Racing and Karting
                </Typography>
              </ListItem>
            </List>
            <Typography variant='body2'>
              We’re not like the other car clubs out there. BOX BOX is a club
              for car enthusiasts who value community over status.
              <br />
              So we’re building a welcoming space for all car lovers, regardless
              of the make or model.
            </Typography>
          </Box>
          <Stack
            justifyContent={'center'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            sx={{ width: isMobile ? '100%' : '30%' }}
          >
            <Link to='/apply' style={{ textDecoration: 'none' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{
                  marginTop: '1.5rem',
                  marginBottom: isMobile ? '1.25rem !important' : '0rem',
                  height: '2.5rem',
                  color: 'black',
                  borderColor: 'black',
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white'
                  }
                }}
              >
                <Typography color={'inherit'}>Apply Now</Typography>
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Members
