import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import car from '../../assets/mansell_f1_car.png'

const New = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        padding: isMobile ? '4rem 2rem' : '6rem 0',
        background: isMobile
          ? 'linear-gradient(180deg, #868686 15%, #adafb1 80%, #f6f5f9 90%)'
          : 'linear-gradient(180deg, #868686 15%, #adafb1 58%, #f6f5f9 68%)'
      }}
    >
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          padding: isMobile ? '0' : '0 6rem',
          width: '100%'
        }}
      >
        <Stack
          height='100%'
          direction={isMobile ? 'column' : 'row'}
          justifyContent={'space-between'}
        >
          <Stack sx={{ width: isMobile ? '100%' : '30%' }} spacing={4}>
            <Typography
              variant='h4'
              sx={{ lineHeight: '0.8', fontWeight: '500' }}
            >
              We're the new <br />
              kind of Car Club
            </Typography>
            <Stack spacing={2}>
              <Typography variant='body1' sx={{ lineHeight: '1.25' }}>
                A community-centered club that believes car culture should be
                for everyone, offering memorable in-person experiences and the
                chance to collect our own shared fleet of cars worth driving.
              </Typography>
              <Typography variant='body1' sx={{ lineHeight: '1.25' }}>
                And soon we will have clubhouse and garage for members to call
                home.
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              width: isMobile ? '100%' : '70%',
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-end',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                marginRight: isMobile ? '0' : '5rem',
                paddingTop: '1.75rem'
              }}
            >
              <img src={car} alt='F1 Car' width={isMobile ? 300 : 500} />
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default New
