import { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import { storage } from '../../../firebase'
import { ref, getDownloadURL } from 'firebase/storage'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import FileDownloadIcon from '@mui/icons-material/FileDownload'

export default function EventDetails({ event, rsvps }) {
  const [basicDetails, setBasicDetails] = useState(null)
  const [eventInfo, setEventInfo] = useState(null)
  const [attendanceDetails, setAttendanceDetails] = useState(null)
  const [assets, setAssets] = useState(null)

  const fetchAssetUrls = async (filePath) => {
    let url

    try {
      url = await getDownloadURL(ref(storage, filePath))
    } catch (error) {
      console.log('Error fetching asset URL: ', error)
    }
    return url
  }

  useEffect(() => {
    if (!event) return
    const address = event.location.address
    const details = [
      {
        label: 'Id',
        value: event.docId
      },
      {
        label: 'Status',
        value: event.status
      },
      {
        label: 'Date',
        value: dayjs(event.datetime).format('YYYY-MM-DD hh:mm A')
      },
      {
        label: 'Location',
        value: event.location.name
      },
      {
        label: 'Address',
        value: (
          <>
            {address.street && (
              <>
                {address.street}
                <br />
              </>
            )}
            {address.street2 && (
              <>
                {address.street2}
                <br />
              </>
            )}
            {address.city && (
              <>
                {address.city}
                {address.state && `, ${address.state}`}
                {address.zipCode && ` ${address.zipCode}`}
                <br />
              </>
            )}
            {address.country && address.country}
          </>
        )
      },
      {
        label: 'Reservation Link',
        value: event.externalUrl
          ? event.externalUrl
          : `https://boxbox.autos/events/${event.docId}`
      }
    ]

    setBasicDetails(details)

    const info = [
      {
        label: 'Title',
        value: event.title
      },
      {
        label: 'Description',
        value: event.description.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))
      }
    ]

    setEventInfo(info)

    const attendance = [
      {
        label: 'Total RSVPs',
        value: event.rsvpCount
      },
      {
        label: 'Capacity',
        value: event.capacity
      },

      {
        label: 'Max Plus Ones',
        value: event.maxPlusOnesPerRSVP
      },
      {
        label: 'Members Only',
        value: event.membersOnly ? 'Yes' : 'No'
      },
      {
        label: 'Kid Friendly',
        value: event.kidFriendly ? 'Yes' : 'No'
      },
      {
        label: 'Has Fee',
        value: event.hasFee ? 'Yes' : 'No'
      }
    ]

    if (event.hasFee) {
      attendance.push({
        label: 'Member Fee',
        value: `$${event.fee.members}`
      })
      attendance.push({
        label: 'Non-Member Fee',
        value: `$${event.fee.nonMembers}`
      })
    }

    setAttendanceDetails(attendance)

    const getAssets = async () => {
      const assets = [
        {
          label: 'Poster',
          imageUrl: await fetchAssetUrls(
            `gs://boxbox-core.appspot.com/events/${event.docId}_800x1200.png`
          )
        },
        {
          label: 'Thumbnail',
          imageUrl: await fetchAssetUrls(
            `gs://boxbox-core.appspot.com/events/${event.docId}_800x800.png`
          )
        }
      ]

      setAssets(assets)
    }

    getAssets()
  }, [event])

  const handleExport = () => {
    const allRsvps = rsvps
    const rsvpDetails = []

    for (const index in allRsvps) {
      if (allRsvps.hasOwnProperty(index)) {
        const userDoc = allRsvps[index]
        const userRsvps = userDoc.rsvps

        const matchingRsvp = userRsvps.find(
          (rsvp) => rsvp.eventRef.id === event.docId
        )

        if (matchingRsvp) {
          rsvpDetails.push({
            email: userDoc.emailAddress,
            firstName: userDoc.firstName,
            lastName: userDoc.lastName,
            ...matchingRsvp
          })
        }
      }
    }

    // Basic columns
    const columns = [
      'RSVP Date',
      'First Name',
      'Last Name',
      'Email Address',
      'Plus Ones'
    ]

    // Add dynamic question headers
    rsvpDetails.forEach((rsvpDoc) => {
      if (rsvpDoc.answers?.length > 0) {
        rsvpDoc.answers.forEach((qa) => {
          if (qa?.question && !columns.includes(qa.question)) {
            columns.push(qa.question)
          }
        })
      }
    })

    // Function to escape double quotes in CSV fields
    const escapeCsvField = (field) => {
      if (typeof field === 'string') {
        return `"${field.replace(/"/g, '""')}"`
      }
      return `"${field}"`
    }

    // Map data into rows
    const rows = rsvpDetails.map((rsvp) => {
      const rsvpDate = rsvp?.created
        ? rsvp.created.toDate().toLocaleString()
        : ''

      const row = {
        'RSVP Date': escapeCsvField(rsvpDate),
        'First Name': escapeCsvField(rsvp?.firstName || ''),
        'Last Name': escapeCsvField(rsvp?.lastName || ''),
        'Email Address': escapeCsvField(rsvp?.email || ''),
        'Plus Ones': escapeCsvField(
          typeof rsvp?.plusOnes === 'number' ? rsvp.plusOnes : ''
        )
      }

      if (rsvp?.answers?.length > 0) {
        rsvp.answers.forEach((qa) => {
          if (qa?.question) {
            // Store the answer with escaped double quotes
            row[qa.question] = escapeCsvField(qa?.answer || '')
          }
        })
      }

      return row
    })

    // Convert rows to CSV
    const csvContent = [
      columns.map(escapeCsvField).join(','), // Header row, escape headers
      ...rows.map((row) => columns.map((col) => row[col] || '').join(',')) // Data rows
    ].join('\n')

    // Trigger CSV download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'rsvps.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <Stack spacing={3}>
          <Paper>
            <Typography
              variant='h6'
              sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
            >
              Basic Details
            </Typography>
            <Stack spacing={2}>
              {basicDetails &&
                basicDetails.map((details) => {
                  return (
                    <Box key={details.label}>
                      <Typography
                        variant='body1'
                        sx={{
                          marginBottom: '0.25rem',
                          fontWeight: '500',
                          padding: '0 1rem'
                        }}
                      >
                        {details.label}
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          marginBottom: '1rem',
                          padding: '0 1rem',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word'
                        }}
                      >
                        {details.value}
                      </Typography>
                      <Divider />
                    </Box>
                  )
                })}
            </Stack>
          </Paper>
          <Paper>
            <Typography
              variant='h6'
              sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
            >
              Attendance Details
            </Typography>
            <Stack spacing={2}>
              {attendanceDetails &&
                attendanceDetails.map((details) => {
                  return (
                    <Box key={details.label}>
                      <Typography
                        variant='body1'
                        sx={{
                          marginBottom: '0.25rem',
                          fontWeight: '500',
                          padding: '0 1rem'
                        }}
                      >
                        {details.label}
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ marginBottom: '1rem', padding: '0 1rem' }}
                      >
                        {details.value}
                      </Typography>
                      <Divider />
                    </Box>
                  )
                })}
            </Stack>
          </Paper>
          <Paper>
            <Typography
              variant='h6'
              sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
            >
              Assets
            </Typography>
            <Stack spacing={2}>
              {assets &&
                assets.map((details) => {
                  return (
                    <Box key={details.label}>
                      <Typography
                        variant='body1'
                        sx={{
                          marginBottom: '0.25rem',
                          fontWeight: '500',
                          padding: '0 1rem'
                        }}
                      >
                        {details.label}
                      </Typography>
                      <Box sx={{ marginBottom: '1rem', padding: '0 1rem' }}>
                        <img
                          src={details.imageUrl}
                          alt={details.label}
                          style={{ maxWidth: '60%' }}
                        />
                      </Box>
                      <Divider />
                    </Box>
                  )
                })}
            </Stack>
          </Paper>
        </Stack>
      </Grid>
      <Grid item xs={12} md={9}>
        <Stack spacing={3}>
          <Paper>
            <Typography
              variant='h6'
              sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
            >
              Event Description
            </Typography>
            <Stack spacing={2}>
              {eventInfo &&
                eventInfo.map((details) => {
                  return (
                    <Box key={details.label}>
                      <Typography
                        variant='body1'
                        sx={{
                          marginBottom: '0.25rem',
                          fontWeight: '500',
                          padding: '0 1rem'
                        }}
                      >
                        {details.label}
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ marginBottom: '1rem', padding: '0 1rem' }}
                      >
                        {details.value}
                      </Typography>
                      <Divider />
                    </Box>
                  )
                })}
            </Stack>
          </Paper>
          <Paper>
            <Typography
              variant='h6'
              sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
            >
              Attendee Questions
            </Typography>
            <Stack spacing={2}>
              {event &&
                (event.questions && event.questions.length > 0 ? (
                  event.questions.map((obj, i) => (
                    <Box key={i}>
                      <Typography
                        variant='body2'
                        sx={{ marginBottom: '1rem', padding: '0 1rem' }}
                      >
                        {obj.required ? (
                          <>
                            {obj.question}
                            <span style={{ color: 'red' }}> *</span>
                          </>
                        ) : (
                          obj.question
                        )}
                      </Typography>
                      <Divider />
                    </Box>
                  ))
                ) : (
                  <Box>
                    <Typography
                      variant='body2'
                      sx={{
                        marginBottom: '1rem',
                        padding: '0 1rem',
                        fontStyle: 'italic'
                      }}
                    >
                      No Questions Added
                    </Typography>
                    <Divider />
                  </Box>
                ))}
            </Stack>
          </Paper>
          <Paper>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ marginBottom: '2rem', padding: '1rem 1rem 0 1rem' }}
            >
              <Typography variant='h6'>RSVPs</Typography>
              <IconButton aria-label='export' onClick={handleExport}>
                <FileDownloadIcon />
              </IconButton>
            </Stack>
            {rsvps && (
              <TableContainer
                component={Paper}
                elevation={1}
                sx={{ overflowX: 'auto' }}
              >
                <Table>
                  <TableHead sx={{ backgroundColor: '#f9f9f9' }}>
                    <TableRow>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}
                      >
                        Plus Ones
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}
                      >
                        Phone
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rsvps.map((rsvp, i) => {
                      const eventId = event.docId
                      const rsvpDetails = rsvp.rsvps.find(
                        (rsvp) => rsvp.eventRef.id === eventId
                      )

                      return (
                        <TableRow key={i}>
                          <TableCell>{`${rsvp.firstName} ${rsvp.lastName}`}</TableCell>
                          <TableCell>{rsvpDetails.plusOnes}</TableCell>
                          <TableCell>{rsvp.emailAddress}</TableCell>
                          <TableCell>{rsvp.phoneNumber}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  )
}
