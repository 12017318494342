import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const Pillars = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const pillars = [
    {
      num: '1',
      title: (
        <>
          DEMOCRATIZING ACCESS <br />
          TO CAR CULTURE
        </>
      ),
      body: 'We are a club that wants to make it easier for more people to experience and enjoy car culture with unique events and opportunities'
    },
    {
      num: '2',
      title: (
        <>
          DRIVEN <br />
          PEOPLE
        </>
      ),
      body: 'We are a collective of the driven, a group of passionate individuals with different ambitions and aspirations, but come together over a shared love of all things automotive.'
    },
    {
      num: '3',
      title: (
        <>
          CROWD COLLECTING <br />
          UNIQUE CARS
        </>
      ),
      body: 'We embrace the unconventional and the one of a kind. We are redefining vintage car collecting by bringing our resources together to curate a fleet of amazing cars worth driving #NOGARAGEQUEENS'
    },
    {
      num: '4',
      title: (
        <>
          SHARED <br /> SPACE
        </>
      ),
      body: 'Building a safe space for collaboration - today it’s virtual, tomorrow we’ll have a physical space for members to come together, connect and thrive.'
    }
  ]

  return (
    <Box
      sx={{
        backgroundColor: 'white'
      }}
    >
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          width: '100%',
          paddingTop: isMobile ? '1.5rem' : '3rem',
          paddingBottom: isMobile ? '3rem' : '6rem'
        }}
      >
        <Stack
          display='flex'
          alignItems='center'
          sx={{ marginTop: '2rem' }}
          spacing={4}
        >
          <Typography
            variant='h5'
            textAlign={'center'}
            sx={{ fontSize: '1rem' }}
          >
            THE FOUR PILLARS OF BOX BOX
          </Typography>
          <Grid
            container
            justifyContent='space-between'
            spacing={4}
            sx={{ maxWidth: '55rem' }}
          >
            {pillars.map((pillar, index) => (
              <Grid
                item
                xs={isMobile ? 12 : 6}
                sx={{ paddingRight: '3rem' }}
                key={index}
              >
                <Stack direction={'row'} spacing={1}>
                  <Typography
                    variant='h2'
                    sx={{
                      fontSize: '2.5rem',
                      fontWeight: '800',
                      lineHeight: 0.9,
                      marginTop: '-0.2rem'
                    }}
                  >
                    {pillar.num}
                  </Typography>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: '1.25rem',
                        fontWeight: '800',
                        color: 'red',
                        lineHeight: 0.9
                      }}
                    >
                      {pillar.title}
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        fontWeight: '500',
                        marginTop: '0.5rem',
                        lineHeight: 1
                      }}
                    >
                      {pillar.body}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}

export default Pillars
