import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import InputMask from 'react-input-mask'

export default function EventForm(
  handleSubmit,
  formData,
  formStatus,
  handleChange,
  loading,
  isMobile
) {
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        {formStatus === 'not found' && (
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
            <TextField
              name='firstName'
              label='First Name'
              value={formData.firstName}
              onChange={handleChange}
              required
              sx={{
                '& .MuiInputLabel-asterisk': { color: 'red' },
                flex: 1
              }}
            />
            <TextField
              name='lastName'
              label='Last Name'
              value={formData.lastName}
              onChange={handleChange}
              required
              sx={{
                '& .MuiInputLabel-asterisk': { color: 'red' },
                flex: 1
              }}
            />
          </Stack>
        )}
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
          {formStatus === 'not found' ? (
            <TextField
              name='emailAddress'
              label='Email Address'
              value={formData.emailAddress}
              onChange={handleChange}
              required
              sx={{
                '& .MuiInputLabel-asterisk': { color: 'red' },
                flex: 1
              }}
            />
          ) : (
            <InputMask
              mask='(999) 999-9999'
              value={formData.phoneNumber}
              onChange={handleChange}
            >
              {() => (
                <TextField
                  name='phoneNumber'
                  label='Phone Number'
                  required
                  sx={{
                    '& .MuiInputLabel-asterisk': { color: 'red' },
                    flex: 1
                  }}
                />
              )}
            </InputMask>
          )}
        </Stack>
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '2rem !important',
            minWidth: '20rem'
          }}
        >
          <LoadingButton
            type='submit'
            variant='contained'
            color='primary'
            loading={loading}
            sx={{ width: '100%' }}
          >
            {formStatus === 'not found' ? 'Submit' : 'Check In'}
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  )
}
