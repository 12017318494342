import { Link } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'

import instagram from '../../assets/instagram.png'
import facebook from '../../assets/facebook.png'
import linkedin from '../../assets/linkedin.png'

const logo = {
  height: '1rem'
}

const linkStyle = {
  color: '#18272F',
  textDecoration: 'none',
  position: 'relative',
  '&:hover': {
    cursor: 'pointer'
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    borderRadius: '4px',
    backgroundColor: '#18272F',
    bottom: 0,
    left: 0,
    transformOrigin: 'right',
    transform: 'scaleX(0)',
    transition: 'transform .3s ease-in-out'
  },
  '&:hover::before': {
    transformOrigin: 'left',
    transform: 'scaleX(1)'
  }
}

const Footer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const links = [
    {
      name: 'Contact',
      address: '/contact'
    }
    // {
    //   name: 'Careers',
    //   address: '/careers'
    // },
    // {
    //   name: 'Press',
    //   address: '/press'
    // },
    // {
    //   name: 'FAQ',
    //   address: '/faq'
    // },
    // {
    //   name: 'Privacy Policy',
    //   address: '/privacy-policy'
    // },
    // {
    //   name: 'Accessibility',
    //   address: '/accessibility'
    // },
    // {
    //   name: 'Terms & Conditions',
    //   address: '/terms-conditions'
    // },
    // {
    //   name: 'Sitemap',
    //   address: '/sitemap'
    // }
  ]

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderTop: '1px #bdbdbd solid',
        paddingBottom: isMobile ? '1.5rem' : '0'
      }}
    >
      <Container maxWidth='xl' disableGutters>
        <Stack direction={isMobile ? 'column' : 'row'}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent='center'
            alignItems='center'
            spacing={2}
            sx={{ width: isMobile ? '100%' : '90%', padding: '3rem 0' }}
          >
            {links.map((link, index) => {
              return (
                <Typography
                  variant='subtitle2'
                  component={Link}
                  to={link.address}
                  sx={linkStyle}
                  key={index}
                >
                  {link.name}
                </Typography>
              )
            })}
          </Stack>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={2}
            sx={{
              width: isMobile ? '100%' : '10%',
              borderLeft: '1px #bdbdbd solid'
            }}
          >
            {/* <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <a
                href='https://www.instagram.com/boxboxcarclub/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={facebook} alt='facebook-logo' style={logo} />
              </a>
            </Box> */}
            <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <a
                href='https://www.instagram.com/boxboxcarclub/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={instagram} alt='instagram-logo' style={logo} />
              </a>
            </Box>
            <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <a
                href='https://www.linkedin.com/company/boxboxautos'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={linkedin} alt='linkedin-logo' style={logo} />
              </a>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Footer
