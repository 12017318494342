import React, { createContext, useState, useEffect, ReactNode } from 'react'

import { getAuth, onAuthStateChanged, User } from 'firebase/auth'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

// Define the shape of your context data
interface AuthContextData {
  currentUser: User | null
  currentUserData: User | null
}

// Create your context with types
export const AuthContext = createContext<AuthContextData | undefined>(undefined)

// Define the shape of the props
interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [currentUserData, setCurrentUserData] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setCurrentUser(null)
        setCurrentUserData(null)
        setLoading(false)
      } else {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            // @ts-ignore
            user.role = idTokenResult.claims.role
          })
          .catch((error) => {
            console.error(error)
          })

        setCurrentUser(user)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (!currentUser) {
      return
    }
    setCurrentUserData(currentUser)
    setLoading(false)
  }, [currentUser])

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress size={80} sx={{ color: '#0D2439' }} />
      </Box>
    )
  }

  return (
    <AuthContext.Provider value={{ currentUser, currentUserData }}>
      {children}
    </AuthContext.Provider>
  )
}
