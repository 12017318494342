import { FC } from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

type ApplicationInstructionsProps = {
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>
}

const ApplicationInstructions: FC<ApplicationInstructionsProps> = ({
  setShowForm
}) => {
  return (
    <Stack direction={'column'} display={'flex'} alignItems={'center'}>
      <Typography
        variant='h4'
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          marginBottom: '2rem'
        }}
      >
        How to Join BoxBox
      </Typography>
      <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'}>
          <Typography
            variant='h4'
            sx={{ fontWeight: '600', paddingTop: '1rem' }}
          >
            1
          </Typography>
          <List dense={true}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant='body1'
                    sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                  >
                    Submit Your Membership Form
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="At BoxBox, we're more than car enthusiasts; we're life enthusiasts. Be candid in your application—let your personality shine." />
            </ListItem>
            <ListItem>
              <ListItemText primary='Casual and honest responses help us get to know you better.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='Already know someone in BoxBox? Mention them in your application; a referral helps us build a close-knit community.' />
            </ListItem>
          </List>
        </Stack>
        <Stack direction={'row'}>
          <Typography
            variant='h4'
            sx={{ fontWeight: '600', paddingTop: '1rem' }}
          >
            2
          </Typography>
          <List dense={true}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant='body1'
                    sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                  >
                    Await Our Response
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText primary='Our team reviews submissions continually. If you haven’t heard back within two weeks, don’t hesitate to reach out to liam@boxbox.autos.' />
            </ListItem>
          </List>
        </Stack>
        <Stack direction={'row'}>
          <Typography
            variant='h4'
            sx={{ fontWeight: '600', paddingTop: '1rem' }}
          >
            3
          </Typography>
          <List dense={true}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant='body1'
                    sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                  >
                    Next Steps Upon Approval
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText primary='If approved, check your email for the next steps. Joining BoxBox comes with a simple fee structure: $225/month or $2,250/year.' />
            </ListItem>
            <ListItem>
              <ListItemText primary="Not approved this time? You'll receive an email update, and we encourage you to reapply in two months." />
            </ListItem>
          </List>
        </Stack>
      </Stack>
      <Button
        variant='outlined'
        size='large'
        type='submit'
        fullWidth
        onClick={() => setShowForm(true)}
        sx={{
          marginTop: '1.5rem',
          marginBottom: '0rem',
          height: '2.5rem',
          color: 'white',
          borderColor: 'black',
          backgroundColor: 'black',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'white',
            color: 'black'
          }
        }}
      >
        APPLY NOW
      </Button>
    </Stack>
  )
}

export default ApplicationInstructions
