import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { db, storage } from '../../firebase'
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore'
import { ref, getDownloadURL } from 'firebase/storage'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)

const fetchAssetUrls = async (filePath) => {
  let url

  try {
    url = await getDownloadURL(ref(storage, filePath))
  } catch (error) {
    console.log('Error fetching asset URL: ', error)
  }
  return url
}

const Perks = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const [rows, setRows] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const applicationCollection = collection(db, 'events')
      const newApplicationsQuery = query(
        applicationCollection,
        orderBy('datetime', 'desc'),
        limit(4)
      )
      const querySnapshot = await getDocs(newApplicationsQuery)
      const dataPromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data()
        const eventDatetime = dayjs(data.datetime.toDate()).format('MMMM Do')

        const isClosedEvent = dayjs().isAfter(dayjs(data.datetime.toDate()))
        const posterURL = await fetchAssetUrls(
          `gs://boxbox-core.appspot.com/events/${doc.id}_800x1200.png`
        )

        const posterData = {
          id: doc.id,
          date: eventDatetime,
          location: data.location.name,
          name: data.title,
          membersOnly: data.membersOnly,
          link: `/events/${doc.id}`,
          externalUrl: data.externalUrl,
          poster: posterURL,
          status: isClosedEvent ? 'Closed' : data.status
        }

        return posterData
      })

      const data = await Promise.all(dataPromises)
      setRows(data.reverse())
    }

    fetchData()
  }, [])

  const navigateToEvent = (externalUrl, eventLink) => {
    if (externalUrl) {
      window.open(externalUrl, '_blank')
    } else {
      navigate(eventLink)
    }
  }

  return (
    <Box
      backgroundColor='white'
      sx={{ paddingTop: '3rem', paddingBottom: isMobile ? '2rem' : '5rem' }}
    >
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          padding: isMobile ? '0 1rem' : '0 2.5rem',
          width: '100%'
        }}
      >
        <Stack
          height='100%'
          direction={isMobile ? 'column' : 'row'}
          justifyContent={'space-between'}
          spacing={isMobile ? 3 : 0}
        >
          <Box sx={{ width: isMobile ? '100%' : '30%' }}>
            <Typography variant='h6' sx={{ marginBottom: '2rem' }}>
              Building community around epic events
            </Typography>
            <Typography variant='body2'>
              From Formula 1 watch parties to behind-the-scenes tours of private
              Porsche collections, to intimate dinners with F1 insiders, we're
              committed to building a vibrant community through extraordinary
              experiences for all members.
            </Typography>
          </Box>
          <Stack sx={{ width: isMobile ? '100%' : '60%' }}>
            <Typography variant='h6' sx={{ marginBottom: '0.5rem' }}>
              Upcoming Events
            </Typography>
            <Grid container spacing={2} sx={{ padding: '1rem' }}>
              {rows &&
                rows.map((event) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={event.name}
                      sx={{ flexGrow: 1 }}
                    >
                      <Box>
                        <img
                          src={event.poster}
                          alt='Event Poster'
                          style={{ width: '100%' }}
                        />
                      </Box>
                      <Stack
                        direction={isTablet ? 'row' : 'column'}
                        justifyContent={isTablet ? 'space-between' : 'start'}
                        sx={{
                          marginTop: isTablet ? '0.5rem' : '0'
                        }}
                      >
                        <Box>
                          {isMobile && (
                            <Typography
                              variant='h6'
                              sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                height: '1.5rem',
                                width: '100%',
                                lineHeight: 1,

                                fontSize: '1.25rem'
                              }}
                            >
                              {event.name}
                            </Typography>
                          )}
                          <Typography
                            variant='subtitle1'
                            sx={{
                              fontSize: '0.75rem',
                              overflow: 'hidden',
                              marginBottom: '-0.25rem'
                            }}
                          >
                            {event.date}
                          </Typography>
                          <Typography
                            variant='subtitle1'
                            sx={{
                              fontSize: '0.75rem',
                              overflow: 'hidden',
                              marginBottom: '0.5rem'
                            }}
                          >
                            {event.location}
                          </Typography>
                          {!isMobile && (
                            <Typography
                              variant='h6'
                              sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                height: '2.75rem',
                                width: '70%',
                                lineHeight: 1,
                                marginBottom: '0.5rem',
                                fontSize: '1.1rem'
                              }}
                            >
                              {event.name}
                            </Typography>
                          )}
                        </Box>
                        <Box>
                          <Button
                            variant='outlined'
                            size='small'
                            disabled={event.status === 'Closed'}
                            onClick={() =>
                              navigateToEvent(event.externalUrl, event.link)
                            }
                            sx={{
                              color: 'black',
                              borderColor: 'black',
                              borderRadius: 0,
                              width: '4rem',
                              marginBottom: '0.5rem',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                borderColor: 'black'
                              }
                            }}
                          >
                            RSVP
                          </Button>
                          <Typography
                            variant='subtitle1'
                            sx={{ fontSize: '0.75rem', lineHeight: 0.9 }}
                          >
                            {event.membersOnly ? (
                              <>
                                MEMBERS <br></br> ONLY
                              </>
                            ) : (
                              <>
                                GENERAL <br></br> PUBLIC
                              </>
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  )
                })}
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Perks
