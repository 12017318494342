import { FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import ApplicationInstructions from './ApplicationInstructions'
import Form from './Form'

const Apply: FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showForm, setShowForm] = useState<boolean>(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showForm])

  return (
    <Box
      bgcolor='white'
      sx={{
        minHeight: 'calc(100vh - 12.374rem)',
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'center'
      }}
    >
      <Container
        maxWidth='xl'
        disableGutters
        sx={{ padding: isMobile ? '2rem 1rem' : '4rem 0', maxWidth: '40rem' }}
      >
        {showForm ? (
          <Form />
        ) : (
          <ApplicationInstructions setShowForm={setShowForm} />
        )}
      </Container>
    </Box>
  )
}

export default Apply
