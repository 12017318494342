import Stack from '@mui/material/Stack'

import Hero from './Hero'
import Intro from './Intro'
import Events from './Events'
import About from './About'
import RoadAhead from './RoadAhead'
// import Phases from './Phases'
// import Cars from './Cars'
import Membership from '../../components/Membership'
import Newsletter from '../../components/Newsletter'

const Home = () => {
  return (
    <Stack>
      <Hero />
      <Intro />
      <Events />
      <About />
      <RoadAhead />
      {/* <Phases /> */}
      {/* <Cars /> */}
      <Membership />
      <Newsletter />
    </Stack>
  )
}

export default Home
