import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import logo from '../../assets/box_box_logo_blk_letters.png'

const Hero = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box backgroundColor='#F2F1F1'>
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          padding: isMobile ? '0' : '0 1.5rem',
          width: '100%'
        }}
      >
        <Stack
          height='100%'
          direction={'column'}
          justifyContent={'space-between'}
        >
          <Box sx={{ padding: 2 }}>
            <img
              src={logo}
              alt='Logo'
              height={75}
              width={75}
              sx={{
                marginTop: '2rem',
                marginLeft: '2rem'
              }}
            />
          </Box>
          <Box sx={{ padding: 2 }}>
            <Typography variant='h4' sx={{ fontWeight: '400' }}>
              ABOUT US
            </Typography>
          </Box>
          <Box sx={{ padding: 2 }}>
            <Typography
              variant='h2'
              sx={{
                fontWeight: '800',
                fontSize: isMobile ? '1.5rem' : isTablet ? '3rem' : '3.75rem'
              }}
            >
              BOX BOX IS DEDICATED TO MAKING VINTAGE CAR COLLECTING ACCESSIBLE
              AND CREATING A VIBRANT COMMUNITY WHERE WE CAN ALL CELEBRATE CAR
              CULTURE TOGETHER.
            </Typography>
          </Box>
          <Box sx={{ padding: 2, marginBottom: isMobile ? 5 : 10 }}>
            <Typography
              variant='h2'
              sx={{
                fontWeight: '400',
                fontSize: isMobile ? '1.5rem' : isTablet ? '3rem' : '3.75rem'
              }}
            >
              We want to connect, drive and thrive.
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default Hero
