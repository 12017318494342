import { useState, useEffect } from 'react'
import { db } from '../../../firebase'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const Members = () => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const membersCollection = collection(db, 'members')
      const membersQuery = query(
        membersCollection,
        orderBy('status', 'desc'),
        orderBy('name.last', 'desc')
      )
      const querySnapshot = await getDocs(membersQuery)
      const data = querySnapshot.docs.map((doc) => {
        const {
          created,
          updated,
          memberStartDate,
          nextRenewalDate,
          phoneNumber,
          ...restData
        } = doc.data()

        const formatDate = (fireStoredate) => {
          return fireStoredate
            ? dayjs(fireStoredate.toDate()).format('YYYY-MM-DD')
            : '-'
        }

        const c = formatDate(created)
        const u = formatDate(updated)
        const msd = formatDate(memberStartDate)
        const npd = formatDate(nextRenewalDate)

        const data = {
          id: doc.id,
          created: c,
          updated: u,
          memberStartDate: msd,
          nextRenewalDate: npd,
          ...restData
        }

        return data
      })

      setRows(data)
    }

    fetchData()
  }, [])

  const exportToCSV = () => {
    if (rows.length === 0) {
      return
    }

    const exportObj = rows.map((row) => {
      const template = {
        id: row.id || '',
        created: row.created || '',
        name: row.name
          ? {
              first: row.name.first || '',
              last: row.name.last || '',
              fullName: row.name.fullName || ''
            }
          : {
              first: '',
              last: '',
              fullName: ''
            },
        socials: row.socials
          ? {
              twitter: row.socials.twitter || '',
              facebook: row.socials.facebook || '',
              linkedin: row.socials.linkedin || '',
              instagram: row.socials.instagram || ''
            }
          : {
              twitter: '',
              facebook: '',
              linkedin: '',
              instagram: ''
            },
        contactInformation: row.contactInformation
          ? {
              phoneNumber: row.contactInformation.phoneNumber || '',
              email: row.contactInformation.email || '',
              mailingAddress: row.contactInformation.mailingAddress
                ? {
                    street: row.contactInformation.mailingAddress.street || '',
                    street2:
                      row.contactInformation.mailingAddress.street2 || '',
                    city: row.contactInformation.mailingAddress.city || '',
                    state: row.contactInformation.mailingAddress.state || '',
                    zipCode:
                      row.contactInformation.mailingAddress.zipCode || '',
                    country: row.contactInformation.mailingAddress.country || ''
                  }
                : {
                    street: '',
                    street2: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    country: ''
                  }
            }
          : {
              phoneNumber: '',
              email: '',
              mailingAddress: {
                street: '',
                street2: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
              }
            },
        status: row.status || '',
        memberNumber: row.memberNumber || '',
        subscriptionType: row.subscriptionType || '',
        memberStartDate: row.memberStartDate || '',
        apparel: row.apparel
          ? {
              hatStyle: row.apparel.hatStyle || '',
              shirtSize: row.apparel.shirtSize || ''
            }
          : {
              hatStyle: '',
              shirtSize: ''
            },
        favoriteCar: row.favoriteCar || ''
      }

      return template
    })

    const flattenObject = (obj, parent = '') => {
      let result = {}
      for (let key in obj) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          const temp = flattenObject(obj[key], `${parent}${key}.`)
          result = { ...result, ...temp }
        } else if (Array.isArray(obj[key])) {
          result[`${parent}${key}`] = `"${JSON.stringify(obj[key]).replace(
            /"/g,
            '""'
          )}"`
        } else {
          result[`${parent}${key}`] = obj[key]
        }
      }
      return result
    }

    // const flattenedRows = exportObj.map((row) => flattenObject(row))
    const flattenedRows = exportObj.map((row) => {
      const flattened = flattenObject(row)
      return flattened
    })

    const headers = Object.keys(flattenedRows[0])

    const csvRows = flattenedRows.map((row) =>
      headers
        .map((header) => {
          const value = row[header]
          return typeof value === 'string' && value.includes(',')
            ? `"${value.replace(/"/g, '""')}"`
            : value
        })
        .join(',')
    )

    const csvData = [headers.join(','), ...csvRows].join('\n')

    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    const timestamp = Math.floor(Date.now() / 1000)
    a.download = `members_${timestamp}.csv`
    a.click()
  }

  return (
    <Box
      backgroundColor='white'
      sx={{
        minHeight: 'calc(100vh - 12.374rem)',
        display: 'flex',
        paddingBottom: '2.5rem'
      }}
    >
      <Container maxWidth='xl'>
        <Stack direction='column' spacing={3}>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography
              variant='h4'
              sx={{ fontWeight: '500', color: '#18272F' }}
            >
              Members
            </Typography>
            <Button
              variant='text'
              startIcon={<CloudDownloadIcon />}
              sx={{ color: '#18272F' }}
              onClick={exportToCSV}
            >
              Export
            </Button>
          </Stack>
          <TableContainer
            component={Paper}
            elevation={1}
            sx={{ overflowX: 'auto' }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: '#f9f9f9' }}>
                <TableRow>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Name
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Email
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Phone
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Membership Start Date
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Subscription Type
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap', fontWeight: '600' }}>
                    Next Renewal Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.status}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.name.fullName}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.contactInformation.email}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.contactInformation.phoneNumber}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.memberStartDate}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.subscriptionType ? row.subscriptionType : '-'}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row.nextRenewalDate}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </Box>
  )
}

export default Members
