import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

export default function EventDetails({ event }) {
  const [eventDetails, setEventDetails] = useState(null)

  useEffect(() => {
    if (!event) return

    const arr = [
      {
        label: 'Date',
        value: event.date
      },
      {
        label: 'Time',
        value: event.time
      },
      {
        label: 'Event Type',
        value: event.membersOnly ? 'Members Only' : 'Open to Public'
      },
      {
        label: 'Location',
        value: event.location
      },
      {
        label: 'Address',
        value: event.address
      },
      {
        label: 'Plus Ones',
        value:
          event.maxPlusOnesPerRSVP > 0
            ? 'Yes, no more than ' + event.maxPlusOnesPerRSVP
            : 'No'
      },
      {
        label: 'Kid Friendly',
        value: event.kidFriendly ? 'Yes' : 'No'
      }
    ]

    setEventDetails(arr)
  }, [event])

  return (
    <Box>
      <Typography variant='h6'>Event Details</Typography>
      {eventDetails &&
        eventDetails.map(
          (detail) =>
            detail.value && (
              <Stack direction={'row'} spacing={1} key={detail.label}>
                <Typography>{detail.label}:</Typography>
                <Typography>{detail.value}</Typography>
              </Stack>
            )
        )}
      <Divider flexItem sx={{ margin: '1rem 0rem' }} />
    </Box>
  )
}
