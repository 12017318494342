import { FC } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Button from '@mui/material/Button'

type WelcomeStepProps = {
  id: string
  memberInfo: {
    encodedEmail: string
  }
}

const PaymentGateway: FC<WelcomeStepProps> = ({ id, memberInfo }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack direction='column' alignItems={isMobile ? 'strech' : 'center'}>
      <Typography
        variant='h4'
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          marginBottom: '2rem'
        }}
      >
        Let's Get Your Membership Rolling!
      </Typography>
      <Typography variant='body1' paragraph sx={{ textAlign: 'center' }}>
        Welcome to the BOXBOX family! As a founding member, you have the
        flexibility to choose your payment frequency. Opt for a monthly $225
        subscription or a yearly $2,250 bundle—whatever suits you best!
      </Typography>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems={'center'}
        spacing={2}
        sx={{ marginTop: '2.5rem' }}
      >
        <Button
          variant='outlined'
          size='large'
          type='button'
          onClick={() =>
            (window.location.href = `https://buy.stripe.com/eVadUa7c60ae5ry00d?utm_content=${id}&prefilled_email=${memberInfo?.encodedEmail}`)
          }
          sx={{
            padding: isMobile ? '2rem' : '',
            marginTop: '1.5rem',
            marginBottom: isMobile ? '2rem !important' : '0rem',
            height: '2.5rem',
            width: '15rem',
            color: 'white',
            borderColor: 'black',
            backgroundColor: 'black',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'white',
              color: 'black'
            }
          }}
        >
          Go Monthly for $225
        </Button>
        <Button
          variant='outlined'
          size='large'
          type='button'
          onClick={() =>
            (window.location.href = `https://buy.stripe.com/aEUbM2bsmf583jqcN0?utm_content=${id}&prefilled_email=${memberInfo?.encodedEmail}`)
          }
          sx={{
            padding: isMobile ? '2rem' : '',
            marginTop: '1.5rem',
            marginBottom: '0rem',
            height: '2.5rem',
            width: '15rem',
            color: 'white',
            borderColor: 'black',
            backgroundColor: 'black',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'white',
              color: 'black'
            }
          }}
        >
          Go Yearly and Save!
        </Button>
      </Stack>
    </Stack>
  )
}

export default PaymentGateway
