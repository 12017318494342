import { useContext } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Box } from '@mui/system'
import { Helmet } from 'react-helmet'

import Header from './components/Header'
import Footer from './components/Footer'

import About from './pages/About'
import Apply from './pages/Apply'
import Cars from './pages/Cars'
import Contact from './pages/Contact'
import Event from './pages/Event'
import EventConfirmation from './pages/EventConfirmation'
import Home from './pages/Home'
import Login from './pages/Login'
import Membership from './pages/Membership'
import NotFound from './pages/NotFound'
import Profile from './pages/Profile'
import Registration from './pages/Registration'
import CheckIn from './pages/CheckIn'

import AdminDashboard from './pages/Admin/Dashboard'
import AdminApplications from './pages/Admin/Applications'
import AdminApplication from './pages/Admin/Application'
import AdminMembers from './pages/Admin/Members'
import AdminEvents from './pages/Admin/Events'
import AdminEvent from './pages/Admin/Event'
import AdminMessages from './pages/Admin/Messages'

import ScrollToTop from './utilities/ScrollToTop'

import { AuthContext } from './AuthContext'

const PrivateRoute = ({ element }) => {
  const { currentUser } = useContext(AuthContext)

  return currentUser ? element : <Navigate to='/' />
}

const AdminRoutes = () => (
  <Routes>
    <Route path='dashboard' element={<AdminDashboard />} />
    <Route path='applications' element={<AdminApplications />} />
    <Route path='applications/:id' element={<AdminApplication />} />
    <Route path='members' element={<AdminMembers />} />
    <Route path='events' element={<AdminEvents />} />
    <Route path='events/:id' element={<AdminEvent />} />
    <Route path='messages' element={<AdminMessages />} />
  </Routes>
)

function App() {
  const location = useLocation()
  const isCheckInPage = location.pathname.includes('/check-in')

  return (
    <>
      <Helmet>
        <title>BOX BOX Car Club</title>
        <meta
          name='description'
          content='A community-centered club that believes car culture should be for everyone, offering memorable in-person experiences and the right to collect our own shared fleet of cars worth driving.'
        />
      </Helmet>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        {!isCheckInPage && <Header />}
        <ScrollToTop />
        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/membership' element={<Membership />} />
            <Route path='/about' element={<About />} />
            <Route path='/cars' element={<Cars />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/events/:id' element={<Event />} />
            <Route path='/event-confirmation' element={<EventConfirmation />} />
            <Route path='/check-in/:id' element={<CheckIn />} />
            <Route path='/login' element={<Login />} />
            <Route path='/apply' element={<Apply />} />
            <Route path='/registration/:id' element={<Registration />} />
            <Route
              path='/profile'
              element={<PrivateRoute element={<Profile />} />}
            />
            <Route
              path='/admin/*'
              element={<PrivateRoute element={<AdminRoutes />} />}
            />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Box>
        {!isCheckInPage && <Footer />}
      </Box>
    </>
  )
}

export default App
