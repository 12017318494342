import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

export default function EventFees({ event }) {
  console.log()
  return (
    <Box>
      <Typography variant='h6'>Event Fees</Typography>
      <Stack direction={'row'} spacing={1}>
        <Typography>Members Rate:</Typography>
        <Typography>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(event.memberFee)}
          <span></span>
        </Typography>
      </Stack>
      {event.nonMemberFee === 0 ? (
        <></>
      ) : (
        <Stack direction={'row'} spacing={1}>
          <Typography>Standard Rate: </Typography>
          <Typography>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD'
            }).format(event.nonMemberFee)}
          </Typography>
        </Stack>
      )}
      <Typography
        variant='subtitle2'
        sx={{
          fontStyle: 'italic',
          fontWeight: 'normal',
          marginTop: '0.5rem'
        }}
      >
        All fees are per person. Members and their plus ones receive the member
        rate. Non-members and their plus ones pay the standard rate. To receive
        member pricing, please register with the email address associated with
        your membership.
      </Typography>
      <Divider flexItem sx={{ margin: '1rem 0rem' }} />
    </Box>
  )
}
