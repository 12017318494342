import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { db, functions } from '../../../firebase'
import { doc, onSnapshot, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import useMediaQuery from '@mui/material/useMediaQuery'

import EventDetails from './EventDetails'
import EditEvent from './EditEvent'
import { Divider } from '@mui/material'

export default function Event() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [event, setEvent] = useState(null)
  const [rsvps, setRSVPs] = useState([])
  const [showEditForm, setShowEditForm] = useState(false)
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    const applicationRef = doc(db, 'events', id)
    const unsubscribe = onSnapshot(
      applicationRef,
      (applicationSnap) => {
        if (applicationSnap.exists()) {
          const { created, datetime, phoneNumber, ...data } =
            applicationSnap.data()
          const dateCreated = dayjs(created.toDate()).format('YYYY-MM-DD')
          const eventDatetime = datetime.toDate()

          setEvent({
            docId: applicationSnap.id,
            created: dateCreated,
            datetime: eventDatetime,
            ...data
          })

          if (Array.isArray(data.rsvps) && data.rsvps.length > 0) {
            const newRSVPs = []
            const fetchRSVPs = data.rsvps.map((userRef) =>
              getDoc(userRef)
                .then((userDoc) => {
                  if (userDoc.exists()) {
                    newRSVPs.push(userDoc.data())
                  } else {
                    console.warn('User document does not exist:', userRef)
                  }
                })
                .catch((error) => {
                  console.error('Error fetching a user document:', error)
                })
            )

            Promise.all(fetchRSVPs).then(() => setRSVPs(newRSVPs))
          }
        }
      },
      (error) => {
        console.error(
          'Encountered an error while subscribing to document changes: ',
          error
        )
      }
    )

    return () => {
      unsubscribe()
    }
  }, [id])

  const toggleShowEventForm = () => {
    handleClose()
    setShowEditForm(!showEditForm)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAction = async (action) => {
    handleClose()

    try {
      const updateEventStatus = httpsCallable(functions, 'updateEventStatus')
      await updateEventStatus({ docId: id, status: action })
    } catch (error) {
      console.error(error)
    }
  }

  const handleDisplay = async (display) => {
    handleClose()

    try {
      const updateEventDisplay = httpsCallable(functions, 'updateEventDisplay')
      await updateEventDisplay({ docId: id, display })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box
      backgroundColor='white'
      sx={{
        minHeight: 'calc(100vh - 12.374rem)',
        display: 'flex',
        paddingBottom: '2.5rem'
      }}
    >
      {event && (
        <Container maxWidth='xl'>
          <Button
            variant='text'
            onClick={() => navigate(`/admin/events`)}
            sx={{
              color: '#18272F',
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              marginBottom: '1.5rem'
            }}
          >
            <ArrowBackIcon
              fontSize='small'
              sx={{ paddingTop: '0.1rem', marginRight: '0.25rem' }}
            />
            <Typography variant='body1' sx={{ lineHeight: 'inherit' }}>
              Events
            </Typography>
          </Button>
          <Stack
            direction='row'
            justifyContent={'space-between'}
            sx={{ marginBottom: '2.5rem' }}
          >
            <Typography
              variant={'h4'}
              sx={{
                fontWeight: '500',
                color: '#18272F'
              }}
            >
              {event?.title}
            </Typography>
            <Box display='flex' alignItems='center'>
              <Button
                variant={isXsScreen ? 'text' : 'contained'}
                onClick={handleClick}
                sx={{
                  borderRadius: '0.75rem',
                  display: showEditForm ? 'none' : ''
                }}
              >
                {!isXsScreen && (
                  <Typography
                    variant='body1'
                    sx={{ color: 'white', fontWeight: '500' }}
                  >
                    Actions
                  </Typography>
                )}
                <KeyboardArrowDownIcon
                  fontSize={isXsScreen ? 'large' : 'small'}
                  sx={{
                    paddingTop: isXsScreen ? '0rem' : '0.2rem',
                    color: isXsScreen ? 'black' : 'inherit'
                  }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {event.status === 'Open' ? (
                  <MenuItem onClick={() => handleAction('Closed')}>
                    Close
                  </MenuItem>
                ) : (
                  <MenuItem onClick={() => handleAction('Open')}>Open</MenuItem>
                )}
                {event.display ? (
                  <MenuItem onClick={() => handleDisplay(false)}>
                    Remove
                  </MenuItem>
                ) : (
                  <MenuItem onClick={() => handleDisplay(true)}>
                    Display
                  </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={toggleShowEventForm}>Edit</MenuItem>
                <MenuItem onClick={() => handleAction('Archived')}>
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
          {showEditForm ? (
            <EditEvent
              event={event}
              toggleShowEventForm={toggleShowEventForm}
            />
          ) : (
            <EventDetails event={event} rsvps={rsvps} />
          )}
        </Container>
      )}
    </Box>
  )
}
