import { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

import heroVideo from '../../assets/tatiana_recap_web.mp4'
import heroThumbnail from '../../assets/tatiana_recap_thumbnail.png'
import logo from '../../assets/box_box_logo_wt_letters.png'

const Hero = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [isLoading, setIsLoading] = useState(true)
  const videoRef = useRef(null)

  useEffect(() => {
    videoRef.current.play()
  }, [])

  const handleLoadedData = () => {
    setIsLoading(false)
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        position: 'relative'
      }}
    >
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          padding: isMobile ? '0' : '0 1.5rem',
          width: '100%',
          height: '34rem',
          position: 'relative'
        }}
      >
        {isLoading && (
          <img
            src={heroThumbnail}
            alt='Loading...'
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: 1
            }}
          />
        )}
        <video
          ref={videoRef}
          src={heroVideo}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={handleLoadedData}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 0
          }}
        />
        <Stack
          height='100%'
          direction={'column'}
          justifyContent={'space-between'}
          sx={{
            position: 'absolute',
            width: '100%',
            zIndex: 1
          }}
        >
          <Box sx={{ padding: 2 }}>
            <img
              src={logo}
              alt='Logo'
              height={75}
              width={75}
              sx={{
                marginTop: '2rem',
                marginLeft: '2rem'
              }}
            />
          </Box>
          <Box
            sx={{
              padding: isMobile ? '1rem 1rem 0.25rem' : '2rem 2rem 1.25rem',
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
          >
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent={'space-between'}
              alignItems={isMobile ? 'center' : 'flex-end'}
              spacing={isMobile ? 3 : 0}
            >
              <Stack>
                <Typography color={'white'} sx={{ fontWeight: '500' }}>
                  A Collective for the Driven
                </Typography>
                <Typography
                  color={'#338db9'}
                  sx={{
                    fontSize: '2rem',
                    fontWeight: '600',
                    lineHeight: '0.75'
                  }}
                >
                  {isMobile ? (
                    <>
                      BOX BOX is a club for <br /> car enthusiasts who value
                      community <br />
                      over status.
                    </>
                  ) : (
                    <>
                      BOX BOX is a club for car enthusiasts <br /> who value
                      community over status.
                    </>
                  )}
                </Typography>
                <Typography
                  color={'white'}
                  sx={{
                    fontWeight: '500',
                    marginTop: '1rem',
                    fontSize: isMobile ? '0.9rem' : '1rem'
                  }}
                >
                  Join today and help shape the car culture of tomorrow. <br />
                  Applications are now being accepted at our Miami hub.
                </Typography>
              </Stack>
              <Link to='/apply' style={{ textDecoration: 'none' }}>
                <Button
                  variant='outlined'
                  size='large'
                  sx={{
                    marginBottom: isMobile ? '1.25rem !important' : '0rem',
                    height: '2.5rem',
                    color: 'white',
                    borderColor: 'white',
                    borderRadius: 0,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.25)',
                      borderColor: 'white'
                    }
                  }}
                >
                  <Typography color={'white'}>Apply Now</Typography>
                </Button>
              </Link>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default Hero
