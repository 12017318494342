import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { db } from '../../firebase'

import { collection, getDocs, query, where, addDoc } from 'firebase/firestore'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import Typography from '@mui/material/Typography'

import EventForm from './CheckInForm'

export default function Event() {
  const { id } = useParams()

  const theme = useTheme()
  const [formData, setFormData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formStatus, setFormStatus] = useState('new')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [eventId, setEventId] = useState(null)

  useEffect(() => {
    setEventId(id)
  }, [id])

  useEffect(() => {
    if (!eventId) return

    const formObj = {
      eventId: eventId,
      phoneNumber: '',
      firstName: '',
      lastName: '',
      emailAddress: ''
    }

    setFormData(formObj)
  }, [eventId])

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const nextState = { ...prevState }
      const keys = name.split('[')
      if (keys.length > 1) {
        const index = parseInt(keys[1])
        nextState.answers[index].answer = value
      } else {
        nextState[name] = value
      }
      return nextState
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    if (formStatus === 'new') {
      const phoneNumber = formData.phoneNumber
      const usersCol = collection(db, 'users')
      const usersQuery = query(
        usersCol,
        where('phoneNumber', '==', phoneNumber)
      )
      const usersSnap = await getDocs(usersQuery)

      if (usersSnap.empty) {
        setLoading(false)
        return setFormStatus('not found')
      }

      const user = usersSnap.docs[0].data()

      const checkinCol = collection(db, 'checkins')
      const checkInDoc = {
        ...formData,
        userId: usersSnap.docs[0].id,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        emailAddress: user.emailAddress || ''
      }

      await addDoc(checkinCol, checkInDoc)

      setLoading(false)
      setFormStatus('success')
    } else {
      const checkinCol = collection(db, 'checkins')
      const checkInDoc = {
        ...formData
      }

      await addDoc(checkinCol, checkInDoc)

      setLoading(false)
      setFormStatus('success')
    }
  }

  return (
    <>
      <Box
        bgcolor='white'
        sx={{
          minHeight: 'calc(100vh)',
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'center',
          paddingTop: '6rem'
        }}
      >
        {formData && (
          <Stack
            sx={{
              alignItems: 'center',
              padding: isMobile ? '1rem' : '1rem 5rem 1rem 5rem'
            }}
          >
            {formStatus === 'success' ? (
              <>
                <Typography
                  variant='h4'
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    marginBottom: '.5rem'
                  }}
                >
                  Thank You!
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    marginBottom: '2rem'
                  }}
                >
                  Please enjoy the event.
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant='h4'
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    marginBottom: '.5rem'
                  }}
                >
                  {formStatus === 'not found' ? 'Oops!' : 'Welcome!'}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    marginBottom: '2rem'
                  }}
                >
                  {formStatus === 'not found'
                    ? 'Looks like we need just a little more information from you.'
                    : 'Please enter your phone number below to check in to the event.'}
                </Typography>
              </>
            )}

            {formStatus === 'success' ? (
              <></>
            ) : (
              EventForm(
                handleSubmit,
                formData,
                formStatus,
                handleChange,

                loading,
                isMobile
              )
            )}
          </Stack>
        )}
      </Box>
    </>
  )
}
