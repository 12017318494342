import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import logo from '../../assets/box_box_logo_blk_letters.png'
import card from '../../assets/membership_card.png'

const Hero = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box backgroundColor='#F2F1F1'>
      <Container
        maxWidth='xl'
        disableGutters
        sx={{
          padding: isMobile ? '0' : '0 1.5rem',
          width: '100%'
        }}
      >
        <Stack
          height='100%'
          direction={'column'}
          justifyContent={'space-between'}
        >
          <Box sx={{ padding: 2 }}>
            <img
              src={logo}
              alt='Logo'
              height={75}
              width={75}
              sx={{
                marginTop: '2rem',
                marginLeft: '2rem'
              }}
            />
          </Box>
          <Box sx={{ padding: 2 }}>
            <Typography variant='h4' sx={{ fontWeight: '400' }}>
              MEMBERSHIP
            </Typography>
          </Box>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={isMobile ? 2 : 10}
            sx={{ padding: 2 }}
          >
            <Box sx={{ width: isMobile ? '100%' : '60%' }}>
              <img src={card} alt='Membership Card' width={'100%'} />
            </Box>
            <Box sx={{ width: isMobile ? '100%' : '40%' }}>
              <Typography
                variant='h4'
                sx={{
                  fontSize: '1.9rem',
                  fontWeight: '400',
                  marginBottom: '1.75rem'
                }}
              >
                A COMMUNITY FOR CREATORS, INNOVATORS & THOUGHT LEADERS
              </Typography>
              <Typography variant='body2'>
                We are a collective of the driven, a group of passionate
                individuals with different ambitions and aspirations, who come
                together over a shared love of all things automotive. And we’re
                passionate about having a positive impact on car culture,
                starting here in Miami. If that’s something you’re into, then
                apply today and meet with us for an interview.
              </Typography>
              <Link to='/apply' style={{ textDecoration: 'none' }}>
                <Button
                  variant='outlined'
                  size='large'
                  sx={{
                    marginTop: '1.5rem',
                    marginBottom: isMobile ? '1.25rem !important' : '0rem',
                    height: '2.5rem',
                    color: 'black',
                    borderColor: 'black',
                    borderRadius: 0,
                    '&:hover': {
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white'
                    }
                  }}
                >
                  <Typography color={'inherit'}>Apply Now</Typography>
                </Button>
              </Link>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Hero
