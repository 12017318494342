import { FC, useState } from 'react'
import { db } from '../../firebase'
import {
  updateDoc,
  doc,
  serverTimestamp,
  Timestamp,
  DocumentReference
} from 'firebase/firestore'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import CircularProgress from '@mui/material/CircularProgress'

import validateAddress from './validateAddress'

type WelcomeStepProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  id: string
  registrationData: {
    apparel?: {
      hatStyle: 'Flat Brim' | 'Trucker'
      shirtSize:
        | 'X-Small'
        | 'Small'
        | 'Medium'
        | 'Large'
        | 'X-Large'
        | 'XX-Large'
    }
    contactInformation?: {
      mailingAddress: {
        city: string
        country: string
        state: string
        street: string
        street2: string
        zipCode: string
      }
    }
    favoriteCar?: string
    updated?: Timestamp
    created: Timestamp
    memberRef: DocumentReference
  }
}

interface FormData {
  apparel: {
    hatStyle: '' | 'Flat Brim' | 'Trucker'
    shirtSize:
      | ''
      | 'X-Small'
      | 'Small'
      | 'Medium'
      | 'Large'
      | 'X-Large'
      | 'XX-Large'
  }
  contactInformation: {
    mailingAddress: {
      city: string
      country: string
      zipCode: string
      state: string
      street: string
      street2?: string
    }
  }
  favoriteCar: string
}

type SubmissionStatus =
  | 'pending'
  | 'processing'
  | 'invalid'
  | 'error'
  | 'approved'

const deepUpdate = (obj: any, path: any, value: any) => {
  if (path.length === 1) {
    return { ...obj, [path[0]]: value }
  }
  return {
    ...obj,
    [path[0]]: deepUpdate(obj[path[0]], path.slice(1), value)
  }
}

const PreferenceForm: FC<WelcomeStepProps> = ({
  setStep,
  id,
  registrationData
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>('pending')
  const [formData, setFormData] = useState<FormData>({
    apparel: {
      hatStyle: registrationData.apparel?.hatStyle || '',
      shirtSize: registrationData.apparel?.shirtSize || ''
    },
    contactInformation: {
      mailingAddress: {
        city: registrationData.contactInformation?.mailingAddress.city || '',
        country:
          registrationData.contactInformation?.mailingAddress.country || '',
        zipCode:
          registrationData.contactInformation?.mailingAddress.zipCode || '',
        state: registrationData.contactInformation?.mailingAddress.state || '',
        street:
          registrationData.contactInformation?.mailingAddress.street || '',
        street2:
          registrationData.contactInformation?.mailingAddress.street2 || ''
      }
    },
    favoriteCar: registrationData.favoriteCar || ''
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value
    const name = e.target.name.split('.')

    setFormData(deepUpdate(formData, name, value))
  }

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const syntheticEvent = {
      target: {
        name: e.target.name || '',
        value: e.target.value
      }
    } as React.ChangeEvent<HTMLInputElement>

    handleChange(syntheticEvent)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setSubmissionStatus('processing')

    const setStatus = (status) => {
      setTimeout(() => {
        setSubmissionStatus(status)
      }, 2500)
    }
    const data = formData
    const fullAddress = data.contactInformation.mailingAddress

    const validationResult = await validateAddress(fullAddress)

    if (validationResult.status === 'system_error') {
      setStatus('error')
      return
    } else if (validationResult.status === 'invalid_address') {
      setStatus('invalid')
      return
    }

    if (typeof validationResult.data !== 'string') {
      const { city, country, zipCode, state, street, street2 } =
        validationResult.data

      data.contactInformation.mailingAddress.city = city
      data.contactInformation.mailingAddress.country = country
      data.contactInformation.mailingAddress.zipCode = zipCode
      data.contactInformation.mailingAddress.state = state
      data.contactInformation.mailingAddress.street = street
      if (street2) {
        data.contactInformation.mailingAddress.street2 = street2
      }
    }

    const docRef = doc(db, 'registrations', id)
    try {
      await updateDoc(docRef, { ...data, updated: serverTimestamp() })
      setStatus('approved')
    } catch (error) {
      console.error('Error updating document:', error)
      setStatus('error')
    }
  }

  const renderBody = (currentSubmissionStatus) => {
    switch (currentSubmissionStatus) {
      case 'processing':
        return (
          <Stack
            display='flex'
            justifyContent={'center'}
            alignItems={'center'}
            spacing={3}
            sx={{ opacity: 0.75, marginTop: '5rem' }}
          >
            <Typography variant='h5' textAlign={'center'}>
              Hang tight while we validate your mailing address. We want to make
              sure your package arrives without a hitch!
            </Typography>
            <CircularProgress sx={{ color: 'black' }} />
          </Stack>
        )
      case 'error':
        return (
          <Stack
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            spacing={3}
            sx={{
              opacity: 1,
              padding: '2rem',
              marginTop: '3rem',
              marginBottom: '3rem'
            }}
          >
            <Typography
              variant='h5'
              textAlign={'center'}
              sx={{ marginBottom: '1rem' }}
            >
              Oops! We hit a little snag while processing your preferences.
              Could you kindly revisit your details and try again? If the issue
              persists, feel free to reach out to us at
              web-support@boxbox.autos.
            </Typography>
            <Button
              variant='outlined'
              size='large'
              fullWidth
              onClick={() => setSubmissionStatus('pending')}
              sx={{
                marginTop: '2.5rem !important',
                marginBottom: '0rem',
                height: '2.5rem',
                color: 'white',
                borderColor: 'black',
                backgroundColor: 'black',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'black'
                }
              }}
            >
              Retry Preferences
            </Button>
          </Stack>
        )
      case 'invalid':
        return (
          <Stack
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            spacing={3}
            sx={{
              opacity: 1,
              padding: '2rem',
              marginTop: '3rem',
              marginBottom: '3rem'
            }}
          >
            <Typography
              variant='h5'
              textAlign={'center'}
              sx={{ marginBottom: '1rem' }}
            >
              Oops! It seems there might be an issue with your address. Would
              you mind taking a moment to review it?
            </Typography>
            <Button
              variant='outlined'
              size='large'
              fullWidth
              onClick={() => setSubmissionStatus('pending')}
              sx={{
                marginTop: '2.5rem !important',
                marginBottom: '0rem',
                height: '2.5rem',
                color: 'white',
                borderColor: 'black',
                backgroundColor: 'black',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'black'
                }
              }}
            >
              Review My Address Details
            </Button>
          </Stack>
        )
      case 'approved':
        return (
          <Stack
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            spacing={3}
            sx={{
              opacity: 1,
              padding: '2rem',
              marginTop: '3rem',
              marginBottom: '3rem'
            }}
          >
            <Typography
              variant='h4'
              gutterBottom
              sx={{
                textAlign: 'center',
                fontWeight: 600,
                marginBottom: '2rem'
              }}
            >
              Looks good!
            </Typography>
            <Typography variant='body1' paragraph sx={{ textAlign: 'center' }}>
              We've fine-tuned the format of your address as displayed below.
              Click the button when you're ready to proceed.
            </Typography>
            <Paper
              elevation={0}
              square
              sx={{
                padding: '2rem'
              }}
            >
              <Typography variant='body1' sx={{ textAlign: 'center' }}>
                {formData.contactInformation.mailingAddress.street}
              </Typography>
              {formData.contactInformation.mailingAddress.street2 && (
                <Typography variant='body1' sx={{ textAlign: 'center' }}>
                  {formData.contactInformation.mailingAddress.street2}
                </Typography>
              )}
              <Typography variant='body1' sx={{ textAlign: 'center' }}>
                {`${formData.contactInformation.mailingAddress.city}, ${formData.contactInformation.mailingAddress.state} ${formData.contactInformation.mailingAddress.zipCode} ${formData.contactInformation.mailingAddress.country}`}
              </Typography>
            </Paper>
            <Button
              variant='outlined'
              size='large'
              fullWidth
              onClick={() => setStep(3)}
              sx={{
                padding: isMobile ? '2rem' : '',
                marginTop: '2.5rem !important',
                marginBottom: '0rem',
                height: '2.5rem',
                color: 'white',
                borderColor: 'black',
                backgroundColor: 'black',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'black'
                }
              }}
            >
              Almost There! Pay Now
            </Button>
          </Stack>
        )
      default:
        return (
          <Stack direction='column' alignItems={isMobile ? 'strech' : 'center'}>
            <Typography
              variant='h4'
              gutterBottom
              sx={{
                textAlign: 'center',
                fontWeight: 600,
                marginBottom: '2rem'
              }}
            >
              Tell us a bit about your preferences!
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant='h6'>Favorite Car</Typography>
                  <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
                    Each welcome kit will arrive with some custom BOXBOX swag
                    along with your favorite car. Please tell use about your
                    favorite car and make it as specific as you'd like.
                  </Typography>
                </Box>
                <TextField
                  name='favoriteCar'
                  label='Favorite Car'
                  value={formData.favoriteCar}
                  onChange={handleChange}
                  required
                  sx={{
                    '& .MuiInputLabel-asterisk': { color: 'red' },
                    flex: 1
                  }}
                />
                <Box>
                  <Typography variant='h6'>Appearl Selection</Typography>
                  <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
                    With BOXBOX, you're in control. Choose your hat style and
                    and size shirt. We have Flat Brim and Trucker style hats
                    avaible, as well as most size T-Shirts.
                  </Typography>
                </Box>
                <div>
                  <Typography variant='body1' gutterBottom>
                    What is your hat style preference?
                    <Box component={'span'} sx={{ color: 'red' }}>
                      {' *'}
                    </Box>
                  </Typography>
                  <FormControl required fullWidth>
                    <Select
                      name={`apparel.hatStyle`}
                      value={formData.apparel.hatStyle}
                      onChange={handleSelectChange}
                      required
                    >
                      {['Flat Brim', 'Trucker'].map((style, index) => (
                        <MenuItem value={style} key={index}>
                          {style}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <Typography variant='body1' gutterBottom>
                    What size shirt do you wear?
                    <Box component={'span'} sx={{ color: 'red' }}>
                      {' *'}
                    </Box>
                  </Typography>
                  <FormControl required fullWidth>
                    <Select
                      name={`apparel.shirtSize`}
                      value={formData.apparel.shirtSize}
                      onChange={handleSelectChange}
                      required
                    >
                      {[
                        'X-Small',
                        'Small',
                        'Medium',
                        'Large',
                        'X-Large',
                        'XX-Large'
                      ].map((style, index) => (
                        <MenuItem value={style} key={index}>
                          {style}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <Box>
                  <Typography variant='h6'>Mailing Adddress</Typography>
                  <Typography variant='subtitle2' sx={{ fontWeight: 'normal' }}>
                    Last but not least, we need to know where we should send
                    your Welcome Kit!
                  </Typography>
                </Box>
                <TextField
                  name='contactInformation.mailingAddress.street'
                  label='Street Address'
                  value={formData.contactInformation.mailingAddress.street}
                  onChange={handleChange}
                  required
                  sx={{
                    flex: 1
                  }}
                />
                <TextField
                  name='contactInformation.mailingAddress.street2'
                  label='Apt, suite, or unit'
                  value={formData.contactInformation.mailingAddress.street2}
                  onChange={handleChange}
                  sx={{
                    '& .MuiInputLabel-asterisk': { color: 'red' },
                    flex: 1
                  }}
                />
                <TextField
                  name='contactInformation.mailingAddress.city'
                  label='City'
                  value={formData.contactInformation.mailingAddress.city}
                  onChange={handleChange}
                  required
                  sx={{
                    '& .MuiInputLabel-asterisk': { color: 'red' },
                    flex: 1
                  }}
                />
                <Stack direction={'row'} spacing={2}>
                  <TextField
                    name='contactInformation.mailingAddress.state'
                    label='State'
                    value={formData.contactInformation.mailingAddress.state}
                    onChange={handleChange}
                    required
                    sx={{
                      '& .MuiInputLabel-asterisk': { color: 'red' },
                      flex: 1
                    }}
                  />
                  <TextField
                    name='contactInformation.mailingAddress.zipCode'
                    label='Zip Code'
                    value={formData.contactInformation.mailingAddress.zipCode}
                    onChange={handleChange}
                    required
                    sx={{
                      '& .MuiInputLabel-asterisk': { color: 'red' },
                      flex: 1
                    }}
                  />
                </Stack>
                <Button
                  variant='outlined'
                  size='large'
                  type='submit'
                  sx={{
                    padding: isMobile ? '2rem' : '',
                    marginTop: '2.5rem !important',
                    marginBottom: '0rem',
                    height: '2.5rem',
                    color: 'white',
                    borderColor: 'black',
                    backgroundColor: 'black',
                    borderRadius: 0,
                    '&:hover': {
                      backgroundColor: 'white',
                      color: 'black'
                    }
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </form>
          </Stack>
        )
    }
  }

  return renderBody(submissionStatus)
}

export default PreferenceForm
