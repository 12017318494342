import { useState, useEffect } from 'react'
import { db } from '../../../firebase'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import Button from '@mui/material/Button'

import AddCircle from '@mui/icons-material/AddCircle'

import MessageForm from './MessageForm'
import MessagesTable from './MessagesTable'

const Messages = () => {
  const [rows, setRows] = useState([])
  const [showMessageForm, setShowMessageForm] = useState(false)

  const fetchData = async () => {
    const applicationCollection = collection(db, 'events')
    const newApplicationsQuery = query(
      applicationCollection,
      orderBy('datetime', 'asc')
    )
    const querySnapshot = await getDocs(newApplicationsQuery)
    const data = querySnapshot.docs.map((doc) => {
      const { created, datetime, ...restData } = doc.data()
      const dateCreated = dayjs(created.toDate()).format('YYYY-MM-DD HH:mm A')
      const eventDatetime = dayjs(datetime.toDate()).format(
        'YYYY-MM-DD HH:mm A'
      )
      return {
        id: doc.id,
        created: dateCreated,
        datetime: eventDatetime,
        ...restData
      }
    })
    setRows(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!showMessageForm) {
      fetchData()
    }
  }, [showMessageForm])

  const toggleShowMessageForm = () => {
    setShowMessageForm(!showMessageForm)
  }

  return (
    <Box
      backgroundColor='white'
      sx={{
        minHeight: 'calc(100vh - 12.374rem)',
        display: 'flex',
        paddingBottom: '2.5rem'
      }}
    >
      <Container maxWidth='xl'>
        <Stack direction='column' spacing={3}>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography
              variant='h4'
              sx={{ fontWeight: '500', color: '#18272F' }}
            >
              Messages
            </Typography>
            <Button
              variant='text'
              startIcon={<AddCircle />}
              sx={{ color: '#18272F', display: showMessageForm ? 'none' : '' }}
              onClick={toggleShowMessageForm}
            >
              Create
            </Button>
          </Stack>
          {showMessageForm ? (
            <MessageForm toggleShowMessageForm={toggleShowMessageForm} />
          ) : (
            <MessagesTable rows={rows} />
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default Messages
