import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import { signOut as firebaseSignOut } from 'firebase/auth'
import { useContext } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import Container from '@mui/material/Container'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

import { AuthContext } from '../../AuthContext'

import logo from '../../assets/box_box_logo_black.png'

const linkStyle = {
  color: '#18272F',
  textDecoration: 'none',
  position: 'relative',
  '&:hover': {
    cursor: 'pointer'
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    borderRadius: '4px',
    backgroundColor: '#18272F',
    bottom: 0,
    left: 0,
    transformOrigin: 'right',
    transform: 'scaleX(0)',
    transition: 'transform .3s ease-in-out'
  },
  '&:hover::before': {
    transformOrigin: 'left',
    transform: 'scaleX(1)'
  }
}

const activeLinkStyle = {
  color: '#18272F',
  textDecoration: 'none',
  position: 'relative',
  '&:hover': {
    cursor: 'pointer'
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '1px',
    borderRadius: '4px',
    backgroundColor: '#18272F',
    bottom: 0,
    left: 0
  },
  '&:hover::before': {}
}

export default function Navigation() {
  const { currentUser } = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const location = useLocation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()

  const navigateToHome = () => {
    navigate('/')
  }

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open)
  }

  const signOut = () => {
    firebaseSignOut(auth)
      .then(() => {
        console.log('User signed out successfully')
      })
      .catch((error) => {
        console.error('Error signing out: ', error)
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const drawer = (
    <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 250, height: '100%' }}
        role='presentation'
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List
          sx={{
            padding: '0px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box>
            <ListItem key='home' component={Link} to='/'>
              <ListItemText primary='Home' />
            </ListItem>
            <ListItem key='membership' component={Link} to='/membership'>
              <ListItemText primary='Membership' />
            </ListItem>
            <ListItem key='about' component={Link} to='/about'>
              <ListItemText primary='About Us' />
            </ListItem>
            <ListItem key='cars' component={Link} to='/cars'>
              <ListItemText primary='Car Fleet' />
            </ListItem>
            {currentUser ? (
              <>
                <Divider />
                <ListItem key='admins'>
                  <ListItemText primary='Admin' />
                </ListItem>
                <ListItem
                  key='dashboard'
                  component={Link}
                  to='/admin/dashboard'
                >
                  <ListItemText
                    primary='Dashboard'
                    sx={{ marginLeft: '10px' }}
                  />
                </ListItem>
                <ListItem
                  key='applications'
                  component={Link}
                  to='/admin/applications'
                >
                  <ListItemText
                    primary='Applications'
                    sx={{ marginLeft: '10px' }}
                  />
                </ListItem>
                <ListItem key='members' component={Link} to='/admin/members'>
                  <ListItemText primary='Members' sx={{ marginLeft: '10px' }} />
                </ListItem>
                <ListItem key='events' component={Link} to='/admin/events'>
                  <ListItemText primary='Events' sx={{ marginLeft: '10px' }} />
                </ListItem>
                <ListItem key='messages' component={Link} to='/admin/messages'>
                  <ListItemText
                    primary='Messages'
                    sx={{ marginLeft: '10px' }}
                  />
                </ListItem>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box sx={{ marginTop: 'auto' }}>
            <ListItem key='contact' component={Link} to='/contact'>
              <ListItemText primary='Contact' />
            </ListItem>
            {currentUser ? (
              <ListItem key='signOut' onClick={signOut}>
                <ListItemText primary='Sign Out' />
              </ListItem>
            ) : (
              <ListItem key='login' component={Link} to='/login'>
                <ListItemText primary='Member Login' />
              </ListItem>
            )}
          </Box>
        </List>
      </Box>
    </Drawer>
  )

  return (
    <>
      <AppBar
        position='static'
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
          paddingBottom: isMobile ? '0' : '1rem'
        }}
      >
        <Container maxWidth='xl' disableGutters>
          <Toolbar sx={{ paddingRight: '0px !important' }}>
            {isMobile ? (
              <>
                <IconButton
                  edge='start'
                  color='black'
                  aria-label='open drawer'
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                {drawer}
              </>
            ) : (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ flexGrow: 1 }}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <Typography
                    component={Link}
                    to='/'
                    variant='body2'
                    sx={location.pathname === '/' ? activeLinkStyle : linkStyle}
                  >
                    Home
                  </Typography>
                  <Typography
                    component={Link}
                    to='/membership'
                    variant='body2'
                    sx={
                      location.pathname === '/membership'
                        ? activeLinkStyle
                        : linkStyle
                    }
                  >
                    Membership
                  </Typography>
                  <Typography
                    component={Link}
                    to='/about'
                    variant='body2'
                    sx={
                      location.pathname === '/about'
                        ? activeLinkStyle
                        : linkStyle
                    }
                  >
                    About Us
                  </Typography>
                  <Typography
                    component={Link}
                    to='/cars'
                    variant='body2'
                    sx={
                      location.pathname === '/cars'
                        ? activeLinkStyle
                        : linkStyle
                    }
                  >
                    Car Fleet
                  </Typography>
                  {currentUser ? (
                    <div>
                      <Typography
                        variant='body2'
                        sx={
                          location.pathname.startsWith('/admin')
                            ? activeLinkStyle
                            : linkStyle
                        }
                        onClick={handleClick}
                      >
                        Admin
                      </Typography>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to='/admin/dashboard'
                        >
                          <Typography variant='body2'>Dashboard</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to='/admin/applications'
                        >
                          <Typography variant='body2'>Applications</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to='/admin/members'
                        >
                          <Typography variant='body2'>Members</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to='/admin/events'
                        >
                          <Typography variant='body2'>Events</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleClose}
                          component={Link}
                          to='/admin/messages'
                        >
                          <Typography variant='body2'>Messages</Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <></>
                  )}
                </Stack>
                <Stack direction={'row'} alignItems={'center'}>
                  <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <Typography
                      component={Link}
                      to='/contact'
                      variant='body2'
                      sx={
                        location.pathname === '/contact'
                          ? activeLinkStyle
                          : linkStyle
                      }
                    >
                      Contact
                    </Typography>
                    <Typography variant='body2'>|</Typography>
                    {currentUser ? (
                      <Typography
                        variant='body2'
                        onClick={signOut}
                        sx={linkStyle}
                      >
                        Sign Out
                      </Typography>
                    ) : (
                      <Typography
                        component={Link}
                        to='/login'
                        variant='body2'
                        sx={
                          location.pathname === '/login'
                            ? activeLinkStyle
                            : linkStyle
                        }
                      >
                        Member Login
                      </Typography>
                    )}
                    <Box
                      sx={{
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                      onClick={navigateToHome}
                    >
                      <img src={logo} alt='Logo' height={55} width={55} />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}
