import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { db, storage, functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { doc, getDoc } from 'firebase/firestore'
import { ref, getDownloadURL } from 'firebase/storage'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Helmet } from 'react-helmet'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Divider from '@mui/material/Divider'

import EventDetails from './EventDetails'
import EventFees from './EventFees'
import EventForm from './EventForm'

dayjs.extend(utc)
dayjs.extend(timezone)

const fetchAssetUrls = async (filePath) => {
  let url

  try {
    url = await getDownloadURL(ref(storage, filePath))
  } catch (error) {
    console.log('Error fetching asset URL: ', error)
  }
  return url
}

export default function Event() {
  const { id } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const theme = useTheme()
  const [formData, setFormData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formStatus, setFormStatus] = useState('loading')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [event, setEvent] = useState(null)

  useEffect(() => {
    const rsvp = queryParams.get('rsvp')
    const stripeSessionId = queryParams.get('session_id')

    if (!rsvp) return setFormStatus('form')

    const fetchConfirmation = async () => {
      const confirmRSVP = httpsCallable(functions, 'confirmRSVP')
      const res = await confirmRSVP({ stripeSessionId })

      if (res.data.message) {
        setFormStatus(res.data.message)
      } else {
        setFormStatus('form')
      }
    }

    fetchConfirmation().catch((error) => {
      console.error('Error fetching user document:', error)
      setFormStatus('form')
    })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const applicationRef = doc(db, 'events', id)
      const applicationSnap = await getDoc(applicationRef)

      if (applicationSnap.exists()) {
        const data = applicationSnap.data()
        const eventDate = dayjs(data.datetime.toDate()).format('dddd, MMM DD')
        const eventtime = dayjs(data.datetime.toDate())
          .tz('America/New_York')
          .format('hh:mmA z')

        const isClosedEvent = dayjs().isAfter(dayjs(data.datetime.toDate()))
        const posterURL = await fetchAssetUrls(
          `gs://boxbox-core.appspot.com/events/${applicationRef.id}_800x800.png`
        )

        const eventData = {
          id: applicationRef.id,
          date: eventDate,
          time: eventtime,
          location: data.location.name,
          description: data.description,
          name: data.title,
          membersOnly: data.membersOnly,
          hasFee: data.hasFee,
          memberFee: data.fee?.members,
          stripeMemberFee: data.fee?.stripeMemberPriceId,
          nonMemberFee: data.fee?.nonMembers,
          stripeNonMemberFee: data.fee?.stripeNonMemberPriceId,
          link: `/events/${doc.id}`,
          poster: posterURL,
          kidFriendly: data.kidFriendly,
          maxPlusOnesPerRSVP: data.maxPlusOnesPerRSVP,
          closedEvent: isClosedEvent,
          questions: data.questions
        }

        if (data.location.address.street) {
          const address = data.location.address

          eventData.address =
            address.street +
            ', ' +
            address.city +
            ', ' +
            address.state +
            ' ' +
            address.zipCode
        }

        return setEvent(eventData)
      }
    }

    fetchData()
  }, [id])

  useEffect(() => {
    if (!event) return

    const formObj = {
      eventId: event.id,
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      plusOnes: 0
    }

    if (event.questions) {
      const questions = event.questions.map((question) => {
        const obj = {}
        obj['question'] = question.question
        obj['answer'] = ''
        return obj
      })

      formObj.answers = questions
    }

    setFormData(formObj)
  }, [event])

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prevState) => {
      const nextState = { ...prevState }
      const keys = name.split('[')
      if (keys.length > 1) {
        const index = parseInt(keys[1])
        nextState.answers[index].answer = value
      } else {
        nextState[name] = value
      }
      return nextState
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const numericalFormData = {
      ...formData,
      plusOnes: parseInt(formData.plusOnes, 10)
    }

    const createRSVP = httpsCallable(functions, 'createRSVP')
    const res = await createRSVP(numericalFormData)

    if (res.data.message === 'pending') {
      window.location.href = res.data.link
    } else if (res.data.message) {
      setFormStatus(res.data.message)
      setLoading(false)
    } else {
      setLoading(false)
      throw new Error('Something went wrong')
    }
  }

  return (
    <>
      <Helmet>
        <title>{event && event.name}</title>
        <meta
          name='description'
          content={event && event.description.substring(0, 160)}
        />
      </Helmet>
      <Box
        bgcolor='white'
        sx={{
          minHeight: 'calc(100vh - 12.374rem)',
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'center'
        }}
      >
        {formData && (
          <Stack sx={{ padding: isMobile ? '1rem' : '1rem 5rem 1rem 5rem' }}>
            <Box sx={{ display: formStatus === 'form' ? '' : 'none' }}>
              <Grid container>
                <Grid item xs={12} sm={7}>
                  <Stack direction='column' alignItems={'left'}>
                    <Typography
                      variant='h4'
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                        marginBottom: '2rem'
                      }}
                    >
                      {event.name}
                    </Typography>
                    <Box
                      sx={{
                        display: isMobile ? 'flex' : 'none',
                        alignItems: 'center',
                        height: '100%',
                        marginBottom: '2rem'
                      }}
                    >
                      <img
                        src={event.poster}
                        alt={event.name}
                        style={{ width: '100%' }}
                      />
                    </Box>
                    <EventDetails event={event} />
                    {event.hasFee && <EventFees event={event} />}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box
                    sx={{
                      display: isMobile ? 'none' : 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}
                  >
                    <img
                      src={event.poster}
                      alt={event.name}
                      style={{ width: '80%' }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Typography variant='h6'>Description</Typography>
                <Typography variant='body1'>
                  {event.description.split('\n').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              </Box>
              <Divider
                flexItem
                sx={{ marginTop: '2rem', marginBottom: '2rem' }}
              />
            </Box>
            <EventForm
              handleSubmit={handleSubmit}
              formData={formData}
              formStatus={formStatus}
              handleChange={handleChange}
              event={event}
              loading={loading}
              isMobile={isMobile}
            />
          </Stack>
        )}
      </Box>
    </>
  )
}
