import { useState } from 'react'
import { db } from '../../firebase'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import Membership from '../../components/Membership'
import Newsletter from '../../components/Newsletter'

const Contact = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validationErrors = {}

    if (!formData.excited) {
      validationErrors.excited = 'Please select an option.'
    }

    const applicationData = { ...formData, created: serverTimestamp() }
    const applicationDoc = collection(db, 'contact')

    await addDoc(applicationDoc, applicationData)

    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    })

    setSubmitted(true)
  }

  return (
    <Stack>
      <Box
        backgroundColor='white'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Container
          maxWidth='xl'
          disableGutters
          sx={{ padding: isMobile ? '2rem 1rem' : '4rem 0' }}
        >
          {submitted ? (
            <Stack
              direction='column'
              alignItems='center'
              sx={{
                minHeight: 'calc(100vh - 20rem)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant='h4'
                gutterBottom
                sx={{
                  textAlign: 'center',
                  fontWeight: 600,
                  marginBottom: '2rem'
                }}
              >
                Thank You!
              </Typography>
              <Typography
                variant='body1'
                gutterBottom
                sx={{ textAlign: 'center', marginBottom: '2rem' }}
              >
                Your message has been received and someone will get in touch
                with you soon.
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction='column'
              alignItems={isMobile ? 'strech' : 'center'}
            >
              <Typography
                variant='h4'
                gutterBottom
                sx={{
                  textAlign: 'center',
                  fontWeight: 600,
                  marginBottom: '2rem'
                }}
              >
                Contact Us
              </Typography>
              <form onSubmit={handleSubmit}>
                <Stack spacing={2} sx={{ marginBottom: '4rem' }}>
                  <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                    <TextField
                      name='firstName'
                      label='First Name'
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                      sx={{
                        '& .MuiInputLabel-asterisk': { color: 'red' },
                        flex: 1
                      }}
                    />
                    <TextField
                      name='lastName'
                      label='Last Name'
                      value={formData.lastName}
                      onChange={handleChange}
                      sx={{
                        '& .MuiInputLabel-asterisk': { color: 'red' },
                        flex: 1
                      }}
                    />
                  </Stack>
                  <TextField
                    name='email'
                    label='Email'
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{ '& .MuiInputLabel-asterisk': { color: 'red' } }}
                  />
                  <TextField
                    name='phone'
                    label='Phone'
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    sx={{ '& .MuiInputLabel-asterisk': { color: 'red' } }}
                  />
                  <TextField
                    name='dreamCar'
                    label={'Message'}
                    value={formData.dreamCar}
                    required
                    sx={{ '& .MuiInputLabel-asterisk': { color: 'red' } }}
                    multiline
                    rows={4}
                    onChange={handleChange}
                  />
                  <Button
                    variant='outlined'
                    size='large'
                    type='submit'
                    sx={{
                      marginTop: '1.5rem',

                      marginBottom: '0rem',
                      height: '2.5rem',
                      color: 'white',
                      borderColor: 'black',
                      backgroundColor: 'black',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: 'white',
                        color: 'black'
                      }
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </form>
            </Stack>
          )}
        </Container>
      </Box>
      <Membership />
      <Newsletter />
    </Stack>
  )
}

export default Contact
